import * as paymentMethods from "../../lib/api/paymentMethods";
import * as posModels from "../../lib/api/posModels";
import * as routeHelpers from "../../lib/routeHelpers";
import cn from "classnames";
import completeIcon from "../../images/complete.svg";
import describeNetworkError from "../../lib/describeNetworkError";
import ErrorModal from "../ErrorModal";
import GiftCardsDrawer from "../GiftCardsDrawer";
import logger from "../../lib/logger";
import OutlineButton from "../OutlineButton";
import React, {useState, useEffect, useRef, FormEvent} from "react";
import Spinner from "../Spinner";
import StyledKeyboard from "../StyledKeyboard";
import styles from "./GiftCardsBalance.module.css";
import TextInputWithResetButton, {
    TextInputWithResetButtonRefHandler,
} from "../TextInputWithResetButton";
import TextModal from "../TextModal";
import {API, Lib} from "habit-core";
import {useAppSelector} from "../../lib/hooks";
import {useExternalInput} from "../../lib/api/useExternalInput";
import {useNavigate} from "react-router-dom";

const strings = {
    // TODO: #261 - support purchasing gift cards as part of regular order
    prohibited: "Action Prohibited",
    giftCards:
        "Adding funds to gift cards at the same time as purchasing menu items is not currently supported. Please complete the order or remove the menu items from your cart to continue.",

    step0Instructions: "Please swipe the guest's card",
    balance: (cardType?: posModels.CardType) =>
        `${cardType === "comp" ? "Comp" : "Gift"} Card Balance Is `,
    addValue: "Add Value To Gift Card",
    cashOut: "Cash-Out Gift Card",
    enterCardNumber: "Enter Card Number",
    submit: "Submit",
    manualInstruction:
        "If Unable To Swipe Card, Enter Card Number Manually Below",
};

const GIFT_CARD_LENGTH = 16;
const SHOW_SWIPE_BUTTON = import.meta.env.REACT_APP_MOCK_API === "true";

export default function GiftCardsBalance() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<{
        type: "warning" | "info";
        title?: string;
        message: string;
    } | null>(null);
    const [step, setStep] = useState(0);
    const [balanceCents, setBalanceCents] = useState<API.models.USDCents>(0);
    const [eligibleForCashOut, setEligibleForCashOut] = useState(false);
    const [cardType, setCardType] = useState<posModels.CardType | undefined>(
        undefined,
    );
    const [cardNumber, setCardNumber] = useState("");
    const [showProhibitModal, setShowProhibitModal] = useState(false);
    const [manualCardNumber, setManualCardNumber] = useState("");
    const onChangeManualCardNumber = (input: string) => {
        setManualCardNumber(input);
    };
    const textInputWithResetButtonRef =
        useRef<TextInputWithResetButtonRefHandler>(null);
    const [keyboardCollapsed, setKeyboardCollapsed] = useState(true);
    const {externalInput, clearExternalInput, cardReadError} = useExternalInput(
        step === 0 && keyboardCollapsed,
    );

    useEffect(() => {
        if (externalInput.length === GIFT_CARD_LENGTH && !cardReadError) {
            getBalance(externalInput);
        }
    }, [externalInput, cardReadError]);

    useEffect(() => {
        if (cardReadError) {
            setError(cardReadError);
        }
    }, [cardReadError]);

    const orderData = useAppSelector((state) =>
        Lib.selectors.getMyOrderData(state),
    );
    const isRegularOrder = !!orderData.length;
    const stationMode = useAppSelector((state) => state.pos.station.mode);

    function getBalance(gcNumber: string) {
        if (loading) {
            return;
        }

        setLoading(true);
        paymentMethods
            .getGiftCardBalance(gcNumber, true)
            .then((data) => {
                setCardNumber(gcNumber);
                setBalanceCents(data.balanceCents);
                setEligibleForCashOut(
                    data.eligibleForCashOut && data.balanceCents !== 0,
                );
                setCardType(data.cardType);
                setStep(1);
            })
            .catch((err) => {
                logger.warn(err);
                let errorType: "warning" | "info" = "warning";
                if (err.response.status === 400) {
                    errorType = "info";
                }
                setError({
                    type: errorType,
                    message: describeNetworkError(err).join("\n"),
                });
                clearExternalInput();
            })
            .finally(() => setLoading(false));
    }

    function onSubmitGiftCardNumberForm(e: FormEvent) {
        e.preventDefault();
        getBalance(manualCardNumber);
    }

    function manualEntryContent() {
        return (
            <>
                <div className={styles.manualInstruction}>
                    {strings.manualInstruction}
                </div>
                <form
                    className={styles.inputContainer}
                    onSubmit={onSubmitGiftCardNumberForm}>
                    <TextInputWithResetButton
                        ref={textInputWithResetButtonRef}
                        value={manualCardNumber}
                        setValue={(value: string) => {
                            setManualCardNumber(value);
                            setError(null);
                        }}
                        onFocus={() => setKeyboardCollapsed(false)}
                        placeholder={strings.enterCardNumber}
                        required={true}
                    />
                    <OutlineButton
                        className={styles.submitButton}
                        label={strings.submit}
                        type="submit"
                        disabled={manualCardNumber.length !== GIFT_CARD_LENGTH}
                        loading={loading}
                    />
                </form>
            </>
        );
    }

    return (
        <>
            <GiftCardsDrawer
                balanceCents={balanceCents}
                left={
                    <>
                        {step === 0 ? (
                            <div className={styles.leftContainer}>
                                <div className={styles.step0}>
                                    {strings.step0Instructions}
                                </div>
                                {loading ? (
                                    <div className={styles.step0Spinner}>
                                        <Spinner />
                                    </div>
                                ) : null}
                                {SHOW_SWIPE_BUTTON ? (
                                    <button
                                        className={styles.mockButton}
                                        onClick={() =>
                                            getBalance("1234123412341234")
                                        }>
                                        mock swipe
                                    </button>
                                ) : (
                                    manualEntryContent()
                                )}
                                <div
                                    className={cn(
                                        styles.keyboardContainer,
                                        keyboardCollapsed &&
                                            styles.keyboardContainerCollapsed,
                                    )}>
                                    <StyledKeyboard
                                        currentInput={manualCardNumber}
                                        visible={!keyboardCollapsed}
                                        setVisible={(val: boolean) =>
                                            setKeyboardCollapsed(!val)
                                        }
                                        onChange={onChangeManualCardNumber}
                                        onPressEnter={() => () => {
                                            if (
                                                manualCardNumber.length !==
                                                GIFT_CARD_LENGTH
                                            ) {
                                                return;
                                            }
                                            getBalance(manualCardNumber);
                                        }}
                                        inputRefs={[
                                            textInputWithResetButtonRef.current
                                                ?.inputRef,
                                        ]}
                                        ignoreKeyboardDismissRefs={[
                                            textInputWithResetButtonRef.current
                                                ?.resetButtonRef,
                                        ]}
                                        layout="numeric"
                                    />
                                </div>
                            </div>
                        ) : null}
                        {step === 1 ? (
                            <div
                                className={cn(
                                    styles.leftContainer,
                                    styles.step1,
                                )}>
                                <div className={styles.step1Top}>
                                    <img src={completeIcon} alt="" />
                                    <div className={styles.step1Text}>
                                        {strings.balance(cardType)}
                                        {Lib.currency.centsToDollarString(
                                            balanceCents,
                                        )}
                                    </div>
                                </div>
                                <div className={styles.step1Buttons}>
                                    <OutlineButton
                                        className={styles.step1Button}
                                        labelClassName={styles.step1ButtonLabel}
                                        mode="blue"
                                        label={strings.addValue}
                                        onClick={() => {
                                            if (isRegularOrder) {
                                                setShowProhibitModal(true);
                                            } else {
                                                navigate(
                                                    routeHelpers.giftCardsAddFunds(),
                                                    {
                                                        state: {
                                                            originalBalanceCents:
                                                                balanceCents,
                                                            cardNumber,
                                                        },
                                                    },
                                                );
                                            }
                                        }}
                                        disabled={
                                            cardType === "comp" ||
                                            stationMode ===
                                                "drive_thru_order_fulfillment"
                                        }
                                    />
                                    <OutlineButton
                                        className={styles.step1Button}
                                        labelClassName={styles.step1ButtonLabel}
                                        mode="blue"
                                        label={strings.cashOut}
                                        disabled={
                                            !eligibleForCashOut ||
                                            cardType === "comp" ||
                                            stationMode ===
                                                "drive_thru_order_fulfillment"
                                        }
                                        onClick={() =>
                                            navigate(
                                                routeHelpers.giftCardsCashOut(),
                                                {
                                                    state: {
                                                        balanceCents,
                                                        cardNumber,
                                                    },
                                                },
                                            )
                                        }
                                    />
                                </div>
                                {showProhibitModal ? (
                                    <TextModal
                                        title={strings.prohibited}
                                        content={strings.giftCards}
                                        onClose={() => navigate(-1)}
                                    />
                                ) : null}
                            </div>
                        ) : null}
                    </>
                }
            />
            {error ? (
                <ErrorModal
                    title={error.title}
                    errorMessage={error.message}
                    onClose={() => {
                        setError(null);
                        clearExternalInput();
                    }}
                    backgroundColor={error.type === "info" ? "grey" : "red"}
                    showITInfo={error.type === "warning"}
                />
            ) : null}
        </>
    );
}
