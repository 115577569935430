import * as routeHelpers from "../../lib/routeHelpers";
import cn from "classnames";
import editIcon from "../../images/edit.svg";
import editIconGrey from "../../images/edit-grey.svg";
import React from "react";
import styles from "./PaymentHeader.module.css";
import {Constants} from "habit-core";
import {Link} from "react-router-dom";
import {useAppSelector} from "../../lib/hooks";

const strings = {
    split: "Split Order",
    eatingInCar: "Eating in Car",
};

const orderTypeStrings = {
    [Constants.orderType.DINE_IN]: "Dine-In",
    [Constants.orderType.TO_GO]: "To-Go",
    [Constants.orderType.CURBSIDE]: "Curbside",
    [Constants.orderType.DELIVERY]: "Delivery",
    [Constants.orderType.DRIVE_THRU]: "Drive-Thru",
};

type Props = {
    showCheckoutReturnPrompt: boolean;
    // onSplit: () => void;
    customerName: string | null;
};

export default function PaymentHeader(props: Props) {
    const orderType = useAppSelector(
        (state) => state.currentOrder.orderType ?? Constants.orderType.DINE_IN,
    );
    const posCurrentOrder = useAppSelector((state) => state.pos.currentOrder);

    function getHeaderText() {
        if (
            posCurrentOrder.driveThruDetails.vehicleColor &&
            posCurrentOrder.driveThruDetails.vehicleType
        ) {
            return `${posCurrentOrder.driveThruDetails.vehicleColor} ${
                posCurrentOrder.driveThruDetails.vehicleType
            }${
                posCurrentOrder.driveThruDetails.eatingInCar
                    ? ` // ${strings.eatingInCar}`
                    : ""
            }`;
        }

        let laneAssignmentText = "Lane 1";
        if (posCurrentOrder.driveThruDetails.laneNumber === 2) {
            laneAssignmentText = "Lane 2";
        }

        let finalText = orderTypeStrings[orderType];
        finalText =
            orderType === "drive_thru"
                ? finalText + " " + laneAssignmentText
                : finalText;
        if (props.customerName) {
            finalText = props.customerName + " // " + finalText;
        }
        return finalText;
    }

    return (
        <div className={styles.top}>
            <Link
                className={cn(
                    styles.editButton,
                    !props.showCheckoutReturnPrompt &&
                        styles.editButtonDisabled,
                )}
                to={routeHelpers.checkout()}
                state={{name: props.customerName, removeRefNumDisabled: true}}>
                <img
                    src={
                        !props.showCheckoutReturnPrompt
                            ? editIconGrey
                            : editIcon
                    }
                />
            </Link>

            {getHeaderText()}

            <div className={styles.spacer} />

            {/* // TODO: #89 - Support split checks
            <OutlineButton
                className={cn(
                    styles.splitButton,
                    props.showCheckoutReturnPrompt &&
                        styles.splitButtonDisabled,
                )}
                label={strings.split}
                mode="blue"
                onClick={props.onSplit}
                disabled={props.showCheckoutReturnPrompt}
            />*/}
        </div>
    );
}
