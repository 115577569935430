import * as paymentMethods from "../../lib/api/paymentMethods";
import * as posActions from "../../lib/posActions";
import * as routeHelpers from "../../lib/routeHelpers";
import cn from "classnames";
import describeNetworkError from "../../lib/describeNetworkError";
import ErrorModal from "../ErrorModal";
import Modal from "../Modal";
import OutlineButton from "../OutlineButton";
import React, {useState, useEffect, FormEvent, useRef, useContext} from "react";
import StyledKeyboard from "../StyledKeyboard";
import styles from "./CheckoutGiftCardPurchase.module.css";
import TextInputWithResetButton, {
    TextInputWithResetButtonRefHandler,
} from "../TextInputWithResetButton";
import {CashierModeContext} from "../CashierModeContext";
import {Lib} from "habit-core";
import {useNavigate} from "react-router-dom";
import {useAppSelector, useAppDispatch} from "../../lib/hooks";
import {useExternalInput} from "../../lib/api/useExternalInput";
import {usePaidContext} from "../OrderSubtotalLayout";

const MAX_GC_AMOUNT_CENTS = parseInt(
    import.meta.env.REACT_APP_MAX_GIFT_CARD_VALUE_CENTS ?? "",
);

const MAX_GC_AMOUNT_STRING =
    Lib.currency.centsToDollarString(MAX_GC_AMOUNT_CENTS);

const strings = {
    payment: "Payment",
    instructions: "Swipe The Gift Card to Add ",
    valueAdded: "Gift Card Value Added",
    giveCard: "Hand the gift card to the guest.",
    enterCardNumber: "Enter Card Number",
    submit: "Submit",
    manualInstruction:
        "If Unable To Swipe Card, Enter Card Number Manually Below",
    cancel: "Cancel",
    cancelModalBody:
        "Are you sure you want to cancel? Doing so will NOT refund the payment taken.",
    yesCancel: "Yes, Cancel",
    neverMind: "No, Never Mind",
    duplicateGcNumErrorTitle: "Duplicate Gift Card Number",
    duplicateGcNumErrorMessage:
        "This card has already been swiped / entered in for this purchase. Please use another card.",
    cantPurchaseCompCard:
        "Funds cannot be added to this card because it is a comp card. Please use a different card.",
    addingExceedsMaxAmount: `Unable to add the requested funds to this card, because it would result in the card being over the max allowed value of 
        ${MAX_GC_AMOUNT_STRING}. Please use a different gift card.`,
};

const GIFT_CARD_LENGTH = 16;
const SHOW_SWIPE_BUTTON = import.meta.env.REACT_APP_MOCK_API === "true";

export default function CheckoutGiftCardPurchase() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const {setGiftCardNumbersRequired} = usePaidContext();
    const {isTraining} = useContext(CashierModeContext);

    const giftCardsToPurchase = useAppSelector(
        (state) => state.pos.currentOrder.giftCards.purchase,
    );
    const storeId = useAppSelector((state) => state.currentOrder.storeId);

    const [purchaseIndex, setPurchaseIndex] = useState(0);
    const [quantityIndex, setQuantityIndex] = useState(0);
    const [showCancelModal, setShowCancelModal] = useState(false);

    const [error, setError] = useState<{
        type: "warning" | "info";
        title?: string;
        message: string;
    } | null>(null);

    const {
        externalInput: cardNumber,
        clearExternalInput: clearCardNumber,
        cardReadError,
    } = useExternalInput(!loading);

    const [numbersEntered, setNumbersEntered] = useState<string[]>([]);

    const isUniqueNumber = (gcNum: string) => {
        return !numbersEntered.includes(gcNum);
    };

    useEffect(() => {
        if (cardNumber.length === GIFT_CARD_LENGTH && !cardReadError) {
            onSwipe(cardNumber);
        }
    }, [cardNumber, cardReadError]);

    useEffect(() => {
        if (cardReadError) {
            setError(cardReadError);
        }
    }, [cardReadError]);

    const currentGiftCardRequest = giftCardsToPurchase[purchaseIndex];

    function onSwipe(gcNum: string) {
        if (!isUniqueNumber(gcNum)) {
            setError({
                type: "info",
                title: strings.duplicateGcNumErrorTitle,
                message: strings.duplicateGcNumErrorMessage,
            });
            clearCardNumber();
            setManualCardNumber("");
            return;
        }

        setLoading(true);
        paymentMethods
            .getGiftCardBalance(gcNum)
            .then((gcData) => {
                if (gcData.cardType === "comp") {
                    setError({
                        type: "info",
                        message: strings.cantPurchaseCompCard,
                    });
                    return;
                } else if (
                    currentGiftCardRequest.balanceCents + gcData.balanceCents >
                    MAX_GC_AMOUNT_CENTS
                ) {
                    setError({
                        type: "info",
                        message: strings.addingExceedsMaxAmount,
                    });
                    return;
                }
                setNumbersEntered((prev) => [...prev, gcNum]);
                currentGiftCardRequest.cardNumbers = [
                    ...(currentGiftCardRequest.cardNumbers ?? []),
                    gcNum,
                ];
                if (
                    purchaseIndex === giftCardsToPurchase.length - 1 &&
                    quantityIndex === currentGiftCardRequest.quantity - 1
                ) {
                    setGiftCardNumbersRequired(false);
                    navigate(routeHelpers.payment());
                } else {
                    if (quantityIndex === currentGiftCardRequest.quantity - 1) {
                        // fulfilled quantities for this balance
                        setPurchaseIndex((prev) => prev + 1);
                        setQuantityIndex(0);
                    } else {
                        setQuantityIndex((prev) => prev + 1);
                    }
                }
            })
            .catch((err) => {
                let errorType: "warning" | "info" = "warning";
                if (err.response && err.response.status === 400) {
                    errorType = "info";
                }
                setError({
                    type: errorType,
                    message: describeNetworkError(err).join("\n"),
                });
            })
            .finally(() => {
                setLoading(false);
                clearCardNumber();
                setManualCardNumber("");
            });
    }

    const [manualCardNumber, setManualCardNumber] = useState("");
    const onChangeManualCardNumber = (input: string) => {
        setManualCardNumber(input);
    };
    const textButtonWithResetButtonRef =
        useRef<TextInputWithResetButtonRefHandler>(null);
    const [keyboardCollapsed, setKeyboardCollapsed] = useState(true);

    const onSubmitGiftCardNumberForm = (e: FormEvent) => {
        e.preventDefault();
        if (!manualCardNumber || manualCardNumber.length !== GIFT_CARD_LENGTH) {
            return;
        }
        onSwipe(manualCardNumber);
    };

    return (
        <>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.instructions}>
                        {strings.instructions}
                        {Lib.currency.centsToDollarString(
                            giftCardsToPurchase[purchaseIndex].balanceCents,
                        )}
                    </div>
                    {giftCardsToPurchase[purchaseIndex].quantity > 1 ? (
                        <div className={styles.instructions}>
                            {quantityIndex + 1}/
                            {giftCardsToPurchase[purchaseIndex].quantity}
                        </div>
                    ) : null}
                    {SHOW_SWIPE_BUTTON ? (
                        <OutlineButton
                            className={styles.swipeButton}
                            label="mock swipe card"
                            onClick={() => onSwipe("1234567890")}
                        />
                    ) : (
                        <>
                            <div className={styles.manualInstruction}>
                                {strings.manualInstruction}
                            </div>
                            <form
                                className={styles.inputContainer}
                                onSubmit={onSubmitGiftCardNumberForm}>
                                <TextInputWithResetButton
                                    ref={textButtonWithResetButtonRef}
                                    value={manualCardNumber}
                                    setValue={(value: string) => {
                                        setManualCardNumber(value);
                                    }}
                                    onFocus={() => setKeyboardCollapsed(false)}
                                    placeholder={strings.enterCardNumber}
                                    required={true}
                                />
                                <OutlineButton
                                    className={styles.submitButton}
                                    label={strings.submit}
                                    type="submit"
                                    disabled={
                                        manualCardNumber.length !==
                                        GIFT_CARD_LENGTH
                                    }
                                    loading={loading}
                                />
                            </form>
                        </>
                    )}
                </div>
                <OutlineButton
                    className={cn(
                        styles.cancelButton,
                        keyboardCollapsed ? undefined : styles.hidden,
                    )}
                    label={strings.cancel}
                    onClick={() => setShowCancelModal(true)}
                    disabled={loading}
                />
                <div
                    className={cn(
                        styles.keyboardContainer,
                        keyboardCollapsed && styles.keyboardContainerCollapsed,
                    )}>
                    <StyledKeyboard
                        currentInput={manualCardNumber}
                        visible={!keyboardCollapsed}
                        setVisible={(val: boolean) =>
                            setKeyboardCollapsed(!val)
                        }
                        onChange={onChangeManualCardNumber}
                        onPressEnter={() => () => {
                            if (manualCardNumber.length !== GIFT_CARD_LENGTH) {
                                return;
                            }
                            onSwipe(manualCardNumber);
                        }}
                        inputRefs={[
                            textButtonWithResetButtonRef.current?.inputRef,
                        ]}
                        ignoreKeyboardDismissRefs={[
                            textButtonWithResetButtonRef.current
                                ?.resetButtonRef,
                        ]}
                        layout="numeric"
                    />
                </div>
            </div>
            {error ? (
                <ErrorModal
                    title={error.title}
                    errorMessage={error.message}
                    onClose={() => {
                        setError(null);
                        clearCardNumber();
                    }}
                    backgroundColor={error.type === "info" ? "grey" : "red"}
                    showITInfo={error.type === "warning"}
                />
            ) : null}
            {showCancelModal ? (
                <Modal
                    className={styles.cancelModal}
                    onClose={() => setShowCancelModal(false)}>
                    <div>
                        <div className={styles.cancelModalText}>
                            {strings.cancelModalBody}
                        </div>
                        <div className={styles.cancelModalButtons}>
                            <OutlineButton
                                label={strings.neverMind}
                                onClick={() => setShowCancelModal(false)}
                            />
                            <OutlineButton
                                className={styles.cancelModalConfirmButton}
                                mode="red"
                                label={strings.yesCancel}
                                onClick={() => {
                                    navigate("/");
                                    dispatch(
                                        posActions.clearCurrentOrder(
                                            storeId ?? "",
                                        ),
                                    ).then(() =>
                                        dispatch(
                                            posActions.reserveCurrentOrderId(
                                                isTraining,
                                            ),
                                        ),
                                    );
                                }}
                            />
                        </div>
                    </div>
                </Modal>
            ) : null}
        </>
    );
}
