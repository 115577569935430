import * as posActions from "../../lib/posActions";
import * as routeHelpers from "../../lib/routeHelpers";
import cn from "classnames";
import Drawer from "../Drawer";
import ErrorModal from "../ErrorModal";
import logger from "../../lib/logger";
import ManagerHeader from "../ManagerHeader";
import ManagerPermissionForm from "../ManagerPermissionForm";
import OutlineButton from "../OutlineButton";
import React, {useContext, useEffect, useMemo, useState} from "react";
import Spinner from "../Spinner";
import styles from "./TillAssignment.module.css";
import tillIcon from "../../images/till.svg";
import ToggleButton from "../ToggleButton";
import usePrevious from "../../lib/usePrevious";
import {CashierModeContext} from "../CashierModeContext";
import {getCurrentAssignments} from "../../lib/api/till";
import {TillAssignmentContext} from "../TillAssignmentContext";
import {useAppDispatch, useAppSelector} from "../../lib/hooks";
import {useNavigate} from "react-router-dom";
import {Actions} from "habit-core";

const strings = {
    title: "Till Assignment",
    mainInstruction: "Assign Till To Register",
    instructions:
        "Please select the assigned till number and connect that till to this register. When completed, tap the button below.",
    noticeTitle: "Important Notice:",
    noticeInstruction:
        "Ensure that there is a total of $300 in cash in the till.",
    assign: "Assign Till",
    loadAssignmentsErrorMessage:
        "Failed to load the list of currently assigned tills.",
    retry: "Retry",
    refreshTills: "Refresh Available Tills",
    skipAssignmentButtonLabel: "Drive-thru Order Taker - No Till",
};

export default function TillAssignment() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const {
        assignedTill,
        setAssignedTill,
        tillAssignmentSkipped,
        setTillAssignmentSkipped,
        trainingModeAssignedTill,
        setTrainingModeAssignedTill,
        assigningTill,
        errorMessage,
        clearErrorMessage,
    } = useContext(TillAssignmentContext);
    const prevAssignedTill = usePrevious(assignedTill);
    const prevTrainingAssignedTill = usePrevious(trainingModeAssignedTill);
    const {isTraining} = useContext(CashierModeContext);
    const stationMode = useAppSelector((state) => state.pos.station.mode);
    const deviceId = useAppSelector((state) => state.pos.deviceId);
    const defaultCategoryId = useAppSelector(
        (state) =>
            state.menus.byId[state.currentOrder.menuId ?? ""]
                ?.defaultCategoryId,
    );
    const menusById = useAppSelector((state) => state.menus.byId);
    const driveThruMenuDefaultCategoryId = useMemo(() => {
        const driveThruMenuId = Object.keys(menusById).find(
            (menuId) =>
                menusById[menuId].type === "lunch_and_dinner_drive_thru",
        );
        return driveThruMenuId
            ? menusById[driveThruMenuId]?.defaultCategoryId
            : undefined;
    }, [menusById]);
    const posCurrentOrder = useAppSelector((state) => state.pos.currentOrder);

    const [tillToAssign, setTillToAssign] = useState(-1);
    const [numTills, setNumTills] = useState(0);
    const [unavailableTills, setUnavailableTills] = useState<number[]>([]);
    const [loadAssignmentsErrored, setLoadAssignmentsErrored] = useState(false);
    const [managerPermissionsDrawerOpen, setManagerPermissionsDrawerOpen] =
        useState(false);

    const loadCurrentAssignments = () => {
        setLoadAssignmentsErrored(false);
        setLoading(true);
        getCurrentAssignments()
            .then((currentAssignments) => {
                setNumTills(currentAssignments.numTills);
                setUnavailableTills(
                    currentAssignments.assignedTills.map((a) => a.tillNumber),
                );
                const assignmentWithDeviceId = deviceId
                    ? currentAssignments.assignedTills.find(
                          (a) => a.deviceId === deviceId,
                      )
                    : undefined;
                if (assignmentWithDeviceId) {
                    setAssignedTill(assignmentWithDeviceId.tillNumber, true);
                }
            })
            .catch((err) => {
                logger.error(err);
                setLoadAssignmentsErrored(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getIsDisabled = (tillNumber: number) => {
        return unavailableTills.indexOf(tillNumber) !== -1;
    };

    const onSuccessfulManagerVerification = () => {
        dispatch(
            posActions.editStationMode("drive_thru_order_taker", "lane_1"),
        );
        dispatch(Actions.currentOrderActions.selectOrderType("drive_thru"));
        if (!posCurrentOrder.id) {
            dispatch(posActions.reserveCurrentOrderId());
        }
        setTillAssignmentSkipped(true);
        navigate(
            driveThruMenuDefaultCategoryId
                ? routeHelpers.category(driveThruMenuDefaultCategoryId)
                : routeHelpers.menu(),
        );
    };

    // we only need to perform the initial load when this component is mounted if there is no assigned till.
    useEffect(() => {
        if (
            (!isTraining && assignedTill === -1) ||
            (isTraining && trainingModeAssignedTill === -1)
        ) {
            loadCurrentAssignments();
        }
    }, []);

    // once a till has been assigned, we need to navigate to the correct screen based on the station mode
    useEffect(() => {
        if (
            (!isTraining && assignedTill !== -1) ||
            (isTraining && trainingModeAssignedTill !== -1)
        ) {
            if (stationMode === "drive_thru_order_fulfillment") {
                navigate(routeHelpers.checkout());
            } else if (defaultCategoryId) {
                navigate(routeHelpers.category(defaultCategoryId));
            }
        }
    }, [
        assignedTill,
        prevAssignedTill,
        stationMode,
        defaultCategoryId,
        isTraining,
        trainingModeAssignedTill,
        prevTrainingAssignedTill,
    ]);

    if (loading) {
        return (
            <div className={styles.container}>
                <ManagerHeader label={strings.title} />
                <div className={styles.loadingContainer}>
                    <Spinner />
                </div>
            </div>
        );
    }

    if (isTraining && trainingModeAssignedTill !== -1) {
        return null;
    }

    if ((!isTraining && assignedTill !== -1) || tillAssignmentSkipped) {
        return null;
    }

    return (
        <>
            <div className={styles.container}>
                <ManagerHeader
                    label={strings.title}
                    buttonLabel={strings.refreshTills}
                    buttonOnClick={loadCurrentAssignments}
                />
                <div className={styles.bodyContainer}>
                    <img className={styles.image} src={tillIcon} alt="" />

                    {!loadAssignmentsErrored ? (
                        <>
                            <div className={styles.mainInstruction}>
                                {strings.mainInstruction}
                            </div>
                            <div className={styles.instruction}>
                                {strings.instructions}
                            </div>
                            <div className={styles.optionContainer}>
                                {Array(numTills)
                                    .fill(0)
                                    .map((_, i) => {
                                        const tillNumber = i + 1;
                                        const isTillSelected =
                                            tillToAssign === tillNumber;
                                        return (
                                            <ToggleButton
                                                key={i}
                                                className={cn(
                                                    styles.tillOption,
                                                    numTills === 6
                                                        ? styles.tillOption6
                                                        : styles.tillOption4,
                                                )}
                                                onChange={() =>
                                                    setTillToAssign(tillNumber)
                                                }
                                                label={tillNumber.toString()}
                                                checked={isTillSelected}
                                                disabled={getIsDisabled(
                                                    tillNumber,
                                                )}
                                            />
                                        );
                                    })}
                            </div>
                            <OutlineButton
                                className={styles.skipAssignmentButton}
                                label={strings.skipAssignmentButtonLabel}
                                labelClassName={styles.skipAssignmentButtonText}
                                mode="blue"
                                onClick={() => {
                                    setManagerPermissionsDrawerOpen(true);
                                }}
                            />
                            <div className={styles.bottom}>
                                <div>
                                    <div className={styles.noticeText}>
                                        {strings.noticeTitle}
                                    </div>
                                    <div className={styles.noticeText}>
                                        {strings.noticeInstruction}
                                    </div>
                                </div>
                                <OutlineButton
                                    label={strings.assign}
                                    onClick={() => {
                                        if (isTraining) {
                                            setTrainingModeAssignedTill(
                                                tillToAssign,
                                            );
                                        } else {
                                            setAssignedTill(tillToAssign);
                                        }

                                        if (
                                            stationMode ===
                                            "drive_thru_order_fulfillment"
                                        ) {
                                            navigate(routeHelpers.checkout());
                                        }
                                    }}
                                    className={styles.assignButton}
                                    disabled={tillToAssign === -1}
                                    loading={assigningTill}
                                />
                            </div>
                        </>
                    ) : (
                        <div>
                            <div className={styles.loadError}>
                                {strings.loadAssignmentsErrorMessage}
                            </div>
                            <div className={styles.retryLoadButtonContainer}>
                                <OutlineButton
                                    label={strings.retry}
                                    onClick={loadCurrentAssignments}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {errorMessage ? (
                <ErrorModal
                    errorMessage={errorMessage}
                    onClose={() => {
                        clearErrorMessage();
                        // error could have happened because we tried to assign a till that was assigned to a different one before we refetched current assignments.
                        loadCurrentAssignments();
                    }}
                    showITInfo
                />
            ) : null}
            {managerPermissionsDrawerOpen ? (
                <Drawer
                    isSmall={true}
                    onClose={() => {
                        setManagerPermissionsDrawerOpen(false);
                    }}>
                    <div className={styles.managerPermissionFormContainer}>
                        <ManagerPermissionForm
                            onSuccess={onSuccessfulManagerVerification}
                        />
                    </div>
                </Drawer>
            ) : null}
        </>
    );
}
