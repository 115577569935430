import * as posModels from "./api/posModels";

const strings = {
    frontOfHouse: "Front Of House",
    front1Station: "Front Cashier 1",
    front2Station: "Front Cashier 2",
    driveThruStation: "Drive-Thru",
    lane1: "Lane 1",
    lane2: "Lane 2",
    both: "Both",
    lineBusterStation: "Line Buster",
    cashier: "Cashier",
    kiosk: "Kiosk",
    web: "Online",
    mobile: "Mobile",
    phone: "Phone Call",
    delivery: "Delivery",
    ezCater: "EzCater",
    pos: "POS",
    cancelledStub: " - CANCELLED",
    erroredStub: " - ERROR",
    curbside: "Curbside",
};

export function getStationNameText(
    stationMode?: posModels.StationMode | null,
    laneAssignment?: 1 | 2,
) {
    let stationModeText = "";
    switch (stationMode) {
        case "front_of_house_1":
            stationModeText = strings.front1Station;
            break;
        case "front_of_house_2":
            stationModeText = strings.front2Station;
            break;
        case "drive_thru_order_fulfillment":
            stationModeText = strings.driveThruStation;
            break;
        case "line_buster":
            stationModeText = strings.lineBusterStation;
            break;
    }

    let laneAssignmentText = "";
    const space = " ";
    switch (laneAssignment) {
        case 1:
            laneAssignmentText = space + strings.lane1;
            break;
        case 2:
            laneAssignmentText = space + strings.lane2;
            break;
    }

    return laneAssignmentText
        ? stationModeText + laneAssignmentText
        : stationModeText;
}

export function getOrderSourceText(
    source: posModels.OrderSource,
    type?: posModels.OrderType,
) {
    if (type === "curbside") {
        return strings.curbside;
    }

    switch (source.toLowerCase()) {
        case "delivery":
        case "doordash":
        case "web_doordash":
        case "mobile_doordash":
        case "postmates":
        case "uber":
        case "grubhub":
        case "ticktuk":
            return strings.delivery;
        case "kiosk":
            return strings.kiosk;
        case "mobile":
            return strings.mobile;
        case "web":
            return strings.web;
        case "phone":
            return strings.phone;
        case "ez_cater":
            return strings.ezCater;
        default:
            return strings.pos;
    }
}
