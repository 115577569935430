import * as routeHelpers from "../../lib/routeHelpers";
import Drawer from "../Drawer";
import OutlineButton from "../OutlineButton";
import React, {useContext, useEffect, useState} from "react";
import styles from "./ManagerRemoveTill.module.css";
import TextButton from "../TextButton/TextButton";
import tillIcon from "../../images/till.svg";
import logger from "../../lib/logger";
import {TillAssignmentContext} from "../TillAssignmentContext";
import ErrorModal from "../ErrorModal";
import {openCashDrawer} from "../../lib/CashDrawer/CashDrawerPlugin";
import {useNavigate} from "react-router-dom";
import {CashierModeContext} from "../CashierModeContext";
import {useAppSelector} from "../../lib/hooks";

const strings = {
    no: "No, Thanks",
    removeHeader: (tillNumber: number) => `Remove Assigned Till #${tillNumber}`,
    removeButtonPrompt: (tillNumber: number) => `Remove Till #${tillNumber}`,
    mainPrompt: "Are you sure you want to remove the currently assigned till?",
    subPrompt:
        "Doing so will require you to select a new till before accepting more orders",
};

export default function ManagerRemoveTill() {
    const navigate = useNavigate();
    const {isTraining, setCashierMode} = useContext(CashierModeContext);
    const {
        assignedTill,
        setAssignedTill,
        trainingModeAssignedTill,
        setTrainingModeAssignedTill,
    } = useContext(TillAssignmentContext);
    const [genericErrorMessage, setGenericErrorMessage] = useState("");

    const currentStationMode = useAppSelector(
        (state) => state.pos.station.mode,
    );

    function onClose() {
        navigate(routeHelpers.managerMenu());
    }

    function onRemoveTill() {
        if (isTraining) {
            setTrainingModeAssignedTill(-1);
            return;
        }

        openCashDrawer()
            .then(() => {
                setAssignedTill(-1);
            })
            .catch((err) => {
                logger.warn(err);
                setGenericErrorMessage(err.message);
            });
    }

    useEffect(() => {
        if (
            (!isTraining && assignedTill === -1) ||
            (isTraining && trainingModeAssignedTill === -1)
        ) {
            setCashierMode({type: "user"});
            navigate(routeHelpers.deviceSetup(), {
                state: {
                    skipCCRegistrationCheck:
                        currentStationMode === "drive_thru_order_taker",
                },
            });
        }
    }, [assignedTill, trainingModeAssignedTill, isTraining]);

    return (
        <Drawer onClose={onClose} isSmall={true}>
            <div className={styles.header}>
                {strings.removeHeader(
                    isTraining ? trainingModeAssignedTill : assignedTill,
                )}
            </div>
            <div className={styles.bodyContainer}>
                <img className={styles.image} src={tillIcon} alt="" />
                <div className={styles.mainPrompt}>{strings.mainPrompt}</div>
                <div className={styles.subPrompt}>{strings.subPrompt}</div>
                <OutlineButton
                    className={styles.removeButton}
                    label={strings.removeButtonPrompt(
                        isTraining ? trainingModeAssignedTill : assignedTill,
                    )}
                    labelClassName={styles.removeButtonLabel}
                    onClick={onRemoveTill}
                    mode="blue"
                />
                <TextButton
                    className={styles.noButton}
                    label={strings.no}
                    onClick={onClose}
                />
            </div>
            {genericErrorMessage ? (
                <ErrorModal
                    errorMessage={genericErrorMessage}
                    onClose={() => setGenericErrorMessage("")}
                    showITInfo
                />
            ) : null}
        </Drawer>
    );
}
