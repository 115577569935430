import cn from "classnames";
import React from "react";
import styles from "./TextButton.module.css";

type Props = {
    className?: string;
    label: string;
    onClick?: () => void;
    disabled?: boolean;
};

function TextButton(props: Props, ref: React.ForwardedRef<HTMLButtonElement>) {
    return (
        <button
            type="button"
            onClick={props.onClick}
            disabled={props.disabled}
            className={cn(
                styles.button,
                props.disabled && styles.disabled,
                props.className,
            )}
            ref={ref}>
            {props.label}
        </button>
    );
}

export default React.forwardRef<HTMLButtonElement, Props>(TextButton);
