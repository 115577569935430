import cn from "classnames";
import FeaturedModifierSelection from "../FeaturedModifierSelection";
import React, {useMemo, useState, useEffect, useRef} from "react";
import StyledKeyboard from "../StyledKeyboard";
import styles from "./ItemEditor.module.css";
import SubstitutionToggle from "../SubstitutionToggle";
import ToggleButton from "../ToggleButton";
import {useAppSelector} from "../../lib/hooks";
import {API, Constants, Lib} from "habit-core";
import ModifierToggleRow from "../ModifierToggleRow";
import TextInputWithResetButton, {
    TextInputWithResetButtonRefHandler,
} from "../TextInputWithResetButton";

const strings = {
    required: "Required Modifiers",
    included: "Included",
    optional: "Optional",
    allPreps: "All Preps",
    anyInterest: "Any interest in adding",
    options: "Options",
    defaultSub: "(Default)",
    allPrepsPrompt: "Begin Typing Here",
    instructionAndAllergy: "Instructions / Allergy",
    allergies: "Allergies",
    instructions: "Instructions",
    clear: "Clear",
};

const ALL_PREP_MOD_CHOICE = "ALL_PREP_CHOICE";
const CONTAINER_PADDING_TOP = 32;

type Props = {
    className?: string;
    itemId: string;
    itemKey: string;
    menuId: string;
    modifierSelections: API.models.ModifierSelectionDict;
    onChangeModifier?: (
        modifierId: string,
        selectionId: string,
        quantity: number,
        isDefault: boolean,
        isRequired: boolean,
    ) => void;
    substitutions?: Lib.selectors.SubstitutionItem[];
    onSubstitute?: (replacingItemId: string) => void;
    options?: API.models.GroupingOption[];
    onChangeOption?: (optionId: string) => void;
};

type FeaturedModSelectionIdName = {
    id: string;
    name: string;
};

function featuredModsPrompt(features: FeaturedModSelectionIdName[]): string {
    if (features.length === 0) {
        return "";
    }

    const featureNames = features.map((x) => x.name);
    const nameStr =
        featureNames.slice(0, -1).join(", ") + " or " + featureNames.slice(-1);

    return `${strings.anyInterest} ${nameStr}?`;
}

export default function ItemEditor(props: Props) {
    const menuItemModifiersById = useAppSelector((state) =>
        state.currentOrder.menuId
            ? state.menuItemModifiers.byMenuId[state.currentOrder.menuId]
            : {},
    );
    const menuItem = useAppSelector((state) =>
        state.currentOrder.menuId
            ? state.menuItems.byMenuId[state.currentOrder.menuId][props.itemId]
            : null,
    );
    const getIngredientMods = useMemo(
        () =>
            Lib.selectors.createGetItemMods(Constants.modifierType.INGREDIENT),
        [],
    );

    const getOptionalMods = useMemo(
        () =>
            Lib.selectors.createGetItemMods(
                Constants.modifierType.OPTIONAL_CHOICE,
            ),
        [],
    );

    const getRequiredMods = useMemo(
        () =>
            Lib.selectors.createGetItemMods(
                Constants.modifierType.REQUIRED_CHOICE,
            ),
        [],
    );

    const [search, setSearch] = useState("");
    const getInstructionMods = useMemo(
        () =>
            Lib.selectors.createGetItemMods(Constants.modifierType.INSTRUCTION),
        [],
    );

    const getAllergyMods = useMemo(
        () => Lib.selectors.createGetItemMods(Constants.modifierType.ALLERGY),
        [],
    );

    const modifiers = useAppSelector((state) => ({
        ingredient: getIngredientMods(
            state,
            props.itemId,
            state.currentOrder.menuId,
        ),
        optional: getOptionalMods(
            state,
            props.itemId,
            state.currentOrder.menuId,
        ),
        required: getRequiredMods(
            state,
            props.itemId,
            state.currentOrder.menuId,
        ),
        allPreps: menuItem?.allPreps
            ? Object.values(state.pos.allPreps.byId)
            : [],
        instruction: getInstructionMods(
            state,
            props.itemId,
            state.currentOrder.menuId,
        ),
        allergy: getAllergyMods(state, props.itemId, state.currentOrder.menuId),
    }));

    const filteredAllPreps = useMemo(() => {
        const lowercaseSearch = search.toLowerCase();
        return modifiers.allPreps.filter(
            (prep) => prep.name?.toLowerCase().indexOf(lowercaseSearch) !== -1,
        );
    }, [search, menuItem?.allPreps]);

    const currentItem = useAppSelector(
        (state) => state.items.byId[props.itemId],
    );

    const featuredMods = useAppSelector((state) => {
        if (
            !currentItem.featuredModifierSelections ||
            currentItem.featuredModifierSelections.length === 0
        ) {
            return [];
        }

        return currentItem.featuredModifierSelections.reduce<
            FeaturedModSelectionIdName[]
        >((arr, featuredModSelection) => {
            const featuredSelection =
                state.featuredSelections.byId[
                    featuredModSelection.featuredModifierSelectionId
                ];
            if (!featuredSelection) {
                return arr;
            }

            const menuItemModifier =
                state.menuItemModifiers.byMenuId[props.menuId][currentItem.id][
                    featuredSelection.modifierId
                ];

            const modifier = state.modifiers.byId[featuredSelection.modifierId];
            if (modifier && menuItemModifier) {
                return [
                    ...arr,
                    {
                        id: featuredModSelection.featuredModifierSelectionId,
                        name: modifier.name,
                    },
                ];
            } else {
                return arr;
            }
        }, []);
    });

    const hasRequiredMods = modifiers.required.length > 0;
    const hasFeaturedMods = featuredMods.length > 0;
    const hasIngredientMods = modifiers.ingredient.length > 0;
    const hasOptionalMods = modifiers.optional.length > 0;
    const hasAllPrepsMods = modifiers.allPreps.length > 0;
    const hasInstructionMods = modifiers.instruction.length > 0;
    const hasAllergyMods = modifiers.allergy.length > 0;

    function getCurrentModView() {
        if (hasIngredientMods) {
            return Constants.modifierType.INGREDIENT;
        } else if (hasOptionalMods) {
            return Constants.modifierType.OPTIONAL_CHOICE;
        } else if (hasInstructionMods || hasAllergyMods) {
            return Constants.modifierType.INSTRUCTION;
        } else if (hasAllPrepsMods) {
            return ALL_PREP_MOD_CHOICE;
        } else {
            return null;
        }
    }

    const [currentModView, setCurrentModView] = useState<string | null>(
        getCurrentModView(),
    );

    useEffect(() => {
        setCurrentModView(getCurrentModView());
    }, [props.itemKey]); // use item key rather than item id bc some combos contain duplicate items

    const [keyboardCollapsed, setKeyboardCollapsed] = useState(true);
    const textInputWithResetButtonRef =
        useRef<TextInputWithResetButtonRefHandler>(null);
    const modViewTogglesRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (
            modViewTogglesRef.current &&
            !keyboardCollapsed &&
            modViewTogglesRef.current.getBoundingClientRect().top -
                CONTAINER_PADDING_TOP >
                0
        ) {
            modViewTogglesRef.current.scrollIntoView({
                block: "start",
                inline: "nearest",
                behavior: "smooth",
            });
        }
    }, [keyboardCollapsed]);

    return (
        <div className={cn(props.className, styles.container)}>
            <div className={styles.scrollableContainer}>
                {props.substitutions && props.substitutions.length ? (
                    <>
                        <div className={styles.sectionHeader}>
                            {strings.options}
                        </div>
                        <div className={styles.substitutions}>
                            {props.substitutions.map((item) => (
                                <SubstitutionToggle
                                    key={item.id}
                                    className={styles.substitutionToggle}
                                    item={item}
                                    checked={props.itemId === item.id}
                                    onChange={() => {
                                        if (props.onSubstitute) {
                                            props.onSubstitute(item.id);
                                        }
                                    }}
                                />
                            ))}
                        </div>
                        <div className={styles.divider} />
                    </>
                ) : null}

                {props.options && props.options.length ? (
                    <>
                        <div className={styles.sectionHeader}>
                            {strings.options}
                        </div>
                        <div className={styles.substitutions}>
                            {props.options.map((o) => {
                                const id = o.itemId ? o.itemId : o.comboId; // should only be used for grouping with items
                                return (
                                    <ToggleButton
                                        className={styles.substitutionToggle}
                                        key={id}
                                        label={o.name}
                                        checked={props.itemId === id}
                                        onChange={() =>
                                            props.onChangeOption?.(id ?? "")
                                        }
                                    />
                                );
                            })}
                        </div>
                        <div className={styles.divider} />
                    </>
                ) : null}

                {hasFeaturedMods ? (
                    <>
                        <div
                            className={cn(
                                styles.sectionHeader,
                                styles.italics,
                            )}>
                            {featuredModsPrompt(featuredMods)}
                        </div>

                        <div className={styles.featuredMods}>
                            {featuredMods.map((x) => (
                                <FeaturedModifierSelection
                                    className={styles.featuredMod}
                                    key={x.id}
                                    id={x.id}
                                    itemId={props.itemId}
                                    menuId={props.menuId}
                                    onChange={props.onChangeModifier}
                                    modifierSelections={
                                        props.modifierSelections
                                    }
                                />
                            ))}
                        </div>
                    </>
                ) : null}

                {hasRequiredMods ? (
                    <>
                        <div className={styles.sectionHeader}>
                            {strings.required}
                        </div>
                        {modifiers.required.map((x) => {
                            const menuItemModifier =
                                menuItemModifiersById[props.itemId][x.id];
                            return (
                                <div key={x.id}>
                                    {menuItemModifier.prompt ? (
                                        <div
                                            className={cn(
                                                styles.sectionHeader,
                                                styles.italics,
                                            )}>
                                            {menuItemModifier.prompt}
                                        </div>
                                    ) : null}
                                    <ModifierToggleRow
                                        className={styles.modifierToggleRow}
                                        data={x}
                                        isRequired={true}
                                        value={
                                            props.modifierSelections[x.id]
                                                ?.selectionId
                                        }
                                        onChange={props.onChangeModifier}
                                    />
                                </div>
                            );
                        })}
                    </>
                ) : null}

                {hasFeaturedMods || hasRequiredMods ? (
                    <div className={styles.divider} />
                ) : null}

                {currentModView ? (
                    <div className={styles.modifiersContainer}>
                        <div
                            ref={modViewTogglesRef}
                            className={styles.modViewToggles}>
                            {hasIngredientMods ? (
                                <ToggleButton
                                    className={styles.modViewToggleButton}
                                    checked={
                                        currentModView ===
                                        Constants.modifierType.INGREDIENT
                                    }
                                    label={strings.included}
                                    onChange={() =>
                                        setCurrentModView(
                                            Constants.modifierType.INGREDIENT,
                                        )
                                    }
                                />
                            ) : null}
                            {hasOptionalMods ? (
                                <ToggleButton
                                    className={styles.modViewToggleButton}
                                    checked={
                                        currentModView ===
                                        Constants.modifierType.OPTIONAL_CHOICE
                                    }
                                    label={strings.optional}
                                    onChange={() =>
                                        setCurrentModView(
                                            Constants.modifierType
                                                .OPTIONAL_CHOICE,
                                        )
                                    }
                                />
                            ) : null}
                            {hasAllPrepsMods ? (
                                <ToggleButton
                                    className={styles.modViewToggleButton}
                                    checked={
                                        currentModView === ALL_PREP_MOD_CHOICE
                                    }
                                    label={strings.allPreps}
                                    onChange={() => {
                                        setCurrentModView(ALL_PREP_MOD_CHOICE);
                                    }}
                                />
                            ) : null}
                            {hasInstructionMods || hasAllergyMods ? (
                                <ToggleButton
                                    className={styles.modViewToggleButton}
                                    checked={
                                        currentModView ===
                                            Constants.modifierType
                                                .INSTRUCTION ||
                                        currentModView ===
                                            Constants.modifierType.ALLERGY
                                    }
                                    label={strings.instructionAndAllergy}
                                    onChange={() =>
                                        setCurrentModView(
                                            Constants.modifierType.INSTRUCTION,
                                        )
                                    }
                                />
                            ) : null}
                        </div>
                        {currentModView === Constants.modifierType.INGREDIENT
                            ? modifiers.ingredient.map((x) => (
                                  <ModifierToggleRow
                                      key={x.id}
                                      className={styles.modifierToggleRow}
                                      data={x}
                                      value={
                                          props.modifierSelections[x.id]
                                              ?.selectionId
                                      }
                                      onChange={props.onChangeModifier}
                                      isRequired={false}
                                  />
                              ))
                            : null}
                        {currentModView ===
                        Constants.modifierType.OPTIONAL_CHOICE
                            ? modifiers.optional.map((x) => (
                                  <ModifierToggleRow
                                      key={x.id}
                                      className={styles.modifierToggleRow}
                                      data={x}
                                      value={
                                          props.modifierSelections[x.id]
                                              ?.selectionId
                                      }
                                      onChange={props.onChangeModifier}
                                      isRequired={false}
                                  />
                              ))
                            : null}
                        {currentModView === ALL_PREP_MOD_CHOICE &&
                        hasAllPrepsMods ? (
                            <>
                                <TextInputWithResetButton
                                    className={styles.allPrepsSearchInput}
                                    placeholder={strings.allPrepsPrompt}
                                    value={search}
                                    setValue={setSearch}
                                    onFocus={() => {
                                        setKeyboardCollapsed(false);
                                    }}
                                    ref={textInputWithResetButtonRef}
                                    resetLabel={strings.clear}
                                />
                                {filteredAllPreps.map((x) => (
                                    <ModifierToggleRow
                                        key={x.id}
                                        className={styles.modifierToggleRow}
                                        data={x}
                                        value={
                                            props.modifierSelections[x.id]
                                                ?.selectionId
                                        }
                                        onChange={(
                                            mId: string,
                                            sId: string,
                                            quantity: number,
                                            isDefault: boolean,
                                            isRequired: boolean,
                                        ) => {
                                            if (props.onChangeModifier) {
                                                props.onChangeModifier(
                                                    mId,
                                                    sId,
                                                    quantity,
                                                    isDefault,
                                                    isRequired,
                                                );
                                            }
                                        }}
                                        isRequired={false}
                                    />
                                ))}
                            </>
                        ) : null}
                        {currentModView ===
                        Constants.modifierType.INSTRUCTION ? (
                            <>
                                <div className={styles.modViewSectionHeader}>
                                    {strings.allergies}
                                </div>
                                {modifiers.allergy.map((x) => (
                                    <ModifierToggleRow
                                        key={x.id}
                                        className={styles.modifierToggleRow}
                                        data={x}
                                        value={
                                            props.modifierSelections[x.id]
                                                ?.selectionId
                                        }
                                        onChange={props.onChangeModifier}
                                        isRequired={false}
                                    />
                                ))}
                                <div className={styles.modViewSectionHeader}>
                                    {strings.instructions}
                                </div>
                                {modifiers.instruction.map((x) => (
                                    <ModifierToggleRow
                                        key={x.id}
                                        className={styles.modifierToggleRow}
                                        data={x}
                                        value={
                                            props.modifierSelections[x.id]
                                                ?.selectionId
                                        }
                                        onChange={props.onChangeModifier}
                                        isRequired={false}
                                    />
                                ))}
                            </>
                        ) : null}
                    </div>
                ) : null}
            </div>
            <div
                className={cn(
                    !keyboardCollapsed
                        ? styles.keyboardContainer
                        : styles.keyboardContainerCollapsed,
                )}>
                <StyledKeyboard
                    currentInput={search}
                    visible={!keyboardCollapsed}
                    setVisible={(val: boolean) => setKeyboardCollapsed(!val)}
                    onChange={(input) => setSearch(input)}
                    onPressEnter={() => setKeyboardCollapsed(true)}
                    inputRefs={[textInputWithResetButtonRef.current?.inputRef]}
                    ignoreKeyboardDismissRefs={[
                        textInputWithResetButtonRef.current?.resetButtonRef,
                    ]}
                    showCloseButton={true}
                />
            </div>
        </div>
    );
}
