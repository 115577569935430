import * as routeHelpers from "../../lib/routeHelpers";
import OutlineButton from "../OutlineButton";
import PaymentsMethodHeader from "../PaymentMethodsHeader";
import React from "react";
import styles from "./OtherPaymentMethods.module.css";
import {useLocation, useNavigate} from "react-router-dom";

const strings = {
    otherPaymentMethods: "Other Payment Options",
    fundraiser: "Fundraiser",
    ezCaterPickup: "EzCater Pickup",
    ezCaterDelivery: "EzCater Delivery",
};

type NameState = {
    name?: string;
};

export function OtherPaymentMethods() {
    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state as NameState;
    const customerName = locationState?.name ?? null;

    return (
        <div>
            <PaymentsMethodHeader
                backPromptLabel={strings.otherPaymentMethods}
                to={routeHelpers.payment()}
            />
            <div className={styles.paymentMethodOptionsContainer}>
                <OutlineButton
                    className={styles.paymentMethodOptionButton}
                    label={strings.fundraiser}
                    onClick={() => {
                        navigate(routeHelpers.fundraiser(), {
                            state: {
                                name: customerName,
                            },
                        });
                    }}
                />
                <OutlineButton
                    className={styles.paymentMethodOptionButton}
                    label={strings.ezCaterPickup}
                    onClick={() => {
                        navigate(routeHelpers.ezCater(true), {
                            state: {
                                name: customerName,
                            },
                        });
                    }}
                />
                <OutlineButton
                    className={styles.paymentMethodOptionButton}
                    label={strings.ezCaterDelivery}
                    onClick={() => {
                        navigate(routeHelpers.ezCater(false), {
                            state: {
                                name: customerName,
                            },
                        });
                    }}
                />
            </div>
        </div>
    );
}
