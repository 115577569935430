import describeNetworkError from "../lib/describeNetworkError";
import logger from "../lib/logger";
import React, {useState, createContext} from "react";
import {logTillAssignment} from "../lib/api/till";
import {openCashDrawer} from "../lib/CashDrawer/CashDrawerPlugin";
import {useAppSelector} from "../lib/hooks";

const strings = {
    cashDrawerError:
        "An unexpected error occurred when establishing a connection with the cash drawer.",
    cashDrawerErrorWithMessage: (errMessage: string) =>
        `There was an error related to the cash drawer: ${errMessage}`,
};

type TillContext = {
    assignedTill: number;
    setAssignedTill: (
        tillNumber: number,
        modifyLocalStateOnly?: boolean,
    ) => void;
    tillAssignmentSkipped: boolean; // can only be true when POS is used as drive_thru_order_taker
    setTillAssignmentSkipped: (newAssignmentSkipped: boolean) => void;
    trainingModeAssignedTill: number;
    setTrainingModeAssignedTill: (tillNumber: number) => void;
    assigningTill: boolean;
    errorMessage: string | undefined;
    clearErrorMessage: () => void;
};

export const TillAssignmentContext = createContext<TillContext>({
    assignedTill: -1,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setAssignedTill: () => {},
    tillAssignmentSkipped: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setTillAssignmentSkipped: () => {},
    trainingModeAssignedTill: -1,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setTrainingModeAssignedTill: () => {},
    assigningTill: false,
    errorMessage: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clearErrorMessage: () => {},
});

export function TillAssignmentProvider(props: {children: React.ReactNode}) {
    const [assignedTill, setAssignedTill] = useState(-1);
    const [tillAssignmentSkipped, setTillAssignmentSkipped] = useState(false);
    const [trainingModeAssignedTill, setTrainingModeAssignedTill] =
        useState(-1);
    const [assigningTill, setAssigningTill] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(
        undefined,
    );
    const deviceId = useAppSelector((state) => state.pos.deviceId);

    const onAssignTill = (tillNumber: number, modifyLocalStateOnly = false) => {
        if (modifyLocalStateOnly) {
            setAssignedTill(tillNumber);
            return;
        }

        setAssigningTill(true);
        // if we can't open the till that is connected, we shouldn't get to a state which makes it seem like we have a successful assignment.
        openCashDrawer()
            .then(() => {
                return logTillAssignment(deviceId ?? "", tillNumber);
            })
            .then(() => {
                setAssignedTill(tillNumber);
            })
            .catch((err) => {
                if (!err.config) {
                    // not an axios error, cash drawer error
                    if (err.message) {
                        setErrorMessage(
                            strings.cashDrawerErrorWithMessage(err.message),
                        );
                    } else {
                        setErrorMessage(strings.cashDrawerError);
                    }
                } else {
                    logger.warn(err);
                    setErrorMessage(describeNetworkError(err).join("\n"));
                }
            })
            .finally(() => {
                setAssigningTill(false);
            });
    };

    const clearErrorMessage = () => {
        setErrorMessage(undefined);
    };

    return (
        <TillAssignmentContext.Provider
            value={{
                assignedTill: assignedTill,
                setAssignedTill: onAssignTill,
                tillAssignmentSkipped: tillAssignmentSkipped,
                setTillAssignmentSkipped: setTillAssignmentSkipped,
                trainingModeAssignedTill: trainingModeAssignedTill,
                setTrainingModeAssignedTill: setTrainingModeAssignedTill,
                assigningTill: assigningTill,
                errorMessage: errorMessage,
                clearErrorMessage: clearErrorMessage,
            }}>
            {props.children}
        </TillAssignmentContext.Provider>
    );
}
