import * as ccMethods from "../lib/api/creditCardScanner";
import * as discountMethods from "../lib/api/discounts";
import * as errors from "./errors";
import * as menuMethods from "../lib/api/menus";
import * as orders from "./api/orders";
import * as paymentMethods from "./api/paymentMethods";
import * as posActionTypes from "../constants/posActionTypes";
import * as posModels from "./api/posModels";
import * as posReducers from "./posReducers";
import * as stationModeStorage from "../lib/stationModeStorage";
import logger from "./logger";
import reduxToPosItemCustomization from "./reduxToPosItemCustomization";
import {AnyAction} from "redux";
import {Actions, API, Constants, Lib, Reducers, ReduxModels} from "habit-core";
import {ThunkAction} from "redux-thunk";
import {v4 as uuid4} from "uuid";
import {MenuItemModifier} from "habit-core/types/api/models";
import {CurrentOrderState} from "habit-core/types/reducers/currentOrderReducer";

type PosRootState = Reducers.RootState & {
    pos: posReducers.PosState;
};

type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    PosRootState,
    unknown,
    AnyAction
>;

export function setDeviceId(deviceId: string): AnyAction {
    return {
        type: posActionTypes.POS_SET_DEVICE_ID,
        deviceId,
    };
}

export function initializeStation(
    mode: posModels.StationMode,
    requiresDriveThruDetails: boolean,
    laneAssignment: string | null,
): AnyAction {
    return {
        type: posActionTypes.POS_STATION_INITIALIZE,
        mode,
        requiresDriveThruDetails,
        laneAssignment,
    };
}

export function editStationModeImmediate(
    stationMode: posModels.StationMode,
    laneAssignment: posModels.LaneAssignment | null,
): AnyAction {
    return {
        type: posActionTypes.POS_STATION_EDIT_MODE,
        stationMode,
        laneAssignment,
    };
}

export function editStationMode(
    stationMode: posModels.StationMode,
    laneAssignment: posModels.LaneAssignment | null,
): AppThunk {
    return async (dispatch, getState) => {
        const state = getState();
        const currentLaneAssignment = state.pos.station.laneAssignment;
        await stationModeStorage.setStoredStationMode(stationMode);
        if (laneAssignment) {
            stationModeStorage.setStoredLaneAssignment(laneAssignment);
        } else if (currentLaneAssignment) {
            stationModeStorage.clearStoredLaneAssignment();
        }
        return dispatch(editStationModeImmediate(stationMode, laneAssignment));
    };
}

export function initializeCharityRoundUpSettingsImmediate(
    isEnabled: boolean,
    mode: posModels.CharityMode | null,
    charityName: string | null,
    prompt: string | null,
    rewardMinimumCents: API.models.USDCents | null,
    rewardMessage: string | null,
): AnyAction {
    return {
        type: posActionTypes.POS_CHARITY_SETTINGS_INITIALIZE,
        isEnabled,
        mode,
        charityName,
        prompt,
        rewardMinimumCents,
        rewardMessage,
    };
}

// copied over from core
function setStoreMenuId(
    storeId: string,
    menuId: string,
    orderType: API.models.OrderType,
): AnyAction {
    return {
        type: Constants.actionTypes.CURRENT_ORDER_SET_STORE_MENU,
        storeId,
        orderType: orderType,
        menuId,
    };
}

export function refreshStoreMenu(
    storeId: string,
    orderType: API.models.OrderType,
): AppThunk {
    return async (dispatch) => {
        const {menus} = await API.menus.getByStore(storeId);
        // order type should only be "dine_in", "carry_out" or "drive_thru"
        let menuType: API.models.MenuType = "lunch_and_dinner";
        if (orderType === "drive_thru") {
            menuType = "lunch_and_dinner_drive_thru";
        }
        const activeMenu = menus?.find((m) => m.type === menuType);
        const menuId = activeMenu?.id;

        if (!menuId) {
            throw new Error(
                `Store ${storeId} does not have a menu for orderType ${orderType}`,
            );
        }

        dispatch(Actions.menuActions.setMenus(menus, storeId));
        dispatch(setStoreMenuId(storeId, menuId, orderType));
    };
}

function initializeAllPrepsImmediate(preps: Lib.selectors.ItemModifierData[]) {
    return {
        type: posActionTypes.POS_ALL_PREPS_INITIALIZE,
        preps,
    };
}

export function initializePosAllPreps(
    storeId: string,
): AppThunk<Promise<void>> {
    return (dispatch) => {
        return menuMethods.getAllPreps(storeId).then((allPrepResponse) => {
            const allPreps = allPrepResponse.preps;
            const formattedAllPreps = allPreps.map((prep) => {
                return {
                    id: prep.id,
                    name: prep.name,
                    selections: prep.selections.map((selection) => {
                        return {
                            id: selection.id,
                            default:
                                prep.defaultModifierSelectionId ===
                                selection.id,
                            name: selection.name,
                            priceCents: selection.priceCents,
                            type: selection.type,
                        };
                    }),
                };
            });
            dispatch(initializeAllPrepsImmediate(formattedAllPreps));
            return;
        });
    };
}

function initializeAddOnsImmediate(
    itemsAddOnsInfo: posModels.ItemAddOnsInfo[],
) {
    return {
        type: posActionTypes.POS_ADD_ONS_INITIALIZE,
        itemsAddOnsInfo,
    };
}

export function initializePosAddOns(): AppThunk<Promise<void>> {
    return (dispatch) => {
        return menuMethods.getItemsAddOnsInfo().then((itemsAddOnsResponse) => {
            const itemsAddOnsInfo = itemsAddOnsResponse.items;
            dispatch(initializeAddOnsImmediate(itemsAddOnsInfo));
            return;
        });
    };
}

function setCurrentOrderId(id: string): AnyAction {
    return {
        type: posActionTypes.POS_CURRENT_ORDER_SET_ID,
        id,
    };
}

function setCurrentOrderReferenceNumber(referenceNumber: number): AnyAction {
    return {
        type: posActionTypes.POS_CURRENT_ORDER_SET_REF_NUM,
        referenceNumber,
    };
}

function setCurrentOrderIdRefNumAndNumber(
    id: string,
    referenceNumber: number,
    orderNumber: number,
): AnyAction {
    return {
        type: posActionTypes.POS_CURRENT_ORDER_SET_ID_REF_NUM_AND_NUMBER,
        id,
        referenceNumber,
        orderNumber,
    };
}

function setCurrentOrderOrderNumber(orderNumber: number): AnyAction {
    return {
        type: posActionTypes.POS_CURRENT_ORDER_SET_ORDER_NUMBER,
        orderNumber,
    };
}

function removeCurrentOrderRefNumImmediate(): AnyAction {
    return {
        type: posActionTypes.POS_CURRENT_ORDER_REMOVE_REF_NUM,
    };
}

export function reserveCurrentOrderId(
    isTrainingOrder = false,
): AppThunk<Promise<void>> {
    return (dispatch, getState) => {
        const state = getState();
        const deviceId = state.pos.deviceId;
        return orders
            .reserveOrderId(deviceId ?? "", isTrainingOrder)
            .then((data) => {
                dispatch(setCurrentOrderId(data.id));
            });
    };
}

export function reserveCurrentOrderRefNumIfNeeded(
    isTrainingOrder = false,
): AppThunk<Promise<void>> {
    return (dispatch, getState) => {
        const state = getState();
        const posCurrentOrder = state.pos.currentOrder;
        if (posCurrentOrder.referenceNumber !== null) {
            return Promise.resolve();
        }

        return orders
            .reserveOrderReferenceNumber(isTrainingOrder)
            .then((data) => {
                dispatch(setCurrentOrderReferenceNumber(data.referenceNumber));
            });
    };
}

export function removeCurrentOrderRefNum(): AppThunk {
    return (dispatch) => {
        dispatch(removeCurrentOrderRefNumImmediate());
    };
}

function clearCurrentOrderImmediate(): AnyAction {
    return {type: posActionTypes.POS_CURRENT_ORDER_CLEAR};
}

function clearCurrentOrderVoidedImmediate(): AnyAction {
    return {type: posActionTypes.POS_CURRENT_ORDER_VOIDED_CLEAR};
}

export function clearCurrentOrder(
    storeId: string,
    nextOrderType: API.models.OrderType = "in_store",
): AppThunk<Promise<void>> {
    return (dispatch) => {
        dispatch(Actions.currentOrderActions.clearCurrentOrder());
        dispatch(clearCurrentOrderImmediate());
        dispatch(clearCurrentOrderVoidedImmediate());
        dispatch(clearPayments());
        return dispatch(
            Actions.currentOrderActions.selectStore(
                storeId,
                nextOrderType,
                false,
            ),
        );
    };
}

export function removeAllProductsFromCurrentOrder(): AppThunk {
    return (dispatch) => {
        dispatch(
            Actions.currentOrderActions.clearCurrentOrderProductsImmediate(),
        );
        dispatch(clearCurrentOrderVoidedImmediate());
    };
}

function setOrders(orders: posModels.OrderParsed[]): AnyAction {
    return {
        type: posActionTypes.POS_ORDERS_SET,
        orders,
    };
}

function addOrder(order: posModels.OrderParsed): AnyAction {
    return {
        type: posActionTypes.POS_ADD_ORDER,
        order,
    };
}

export function getOrders(
    statuses: posModels.OrderStatus[],
    sources: posModels.OrderSource[],
    training = false,
): AppThunk<Promise<posModels.OrderParsed[]>> {
    return (dispatch) => {
        return orders.get(statuses, sources, training).then((data) => {
            dispatch(setOrders(data.orders));
            return data.orders;
        });
    };
}

export function placeCurrentOrder(
    guestName: string | null,
    pagerNumber: number | null,
    isDriveThru: boolean,
    charityRoundUpAmountCents: API.models.USDCents,
    subtotalCents: API.models.USDCents,
    staffTipCents: API.models.USDCents | null,
    taxCents: API.models.USDCents,
    totalCents: API.models.USDCents,
    orderStatus: posModels.OrderStatus,
    driveThruDetails: posModels.DriveThruDetails | null,
    payments: posModels.PaymentDetail[] | null,
    ezCaterOrderType?: "pickup" | "delivery",
): AppThunk<Promise<void>> {
    return async (dispatch, getState) => {
        const state = getState();
        const menuId = state.currentOrder.menuId ?? "";
        const stationMode = state.pos.station.mode;
        const deviceId = state.pos.deviceId;
        const orderId = state.pos.currentOrder.id;
        if (!orderId) {
            return Promise.reject(new Error(errors.ERR_NO_ORDER_ID));
        }
        const referenceNumber = state.pos.currentOrder.referenceNumber;
        if (referenceNumber === null && orderStatus !== "in_creation") {
            return Promise.reject(new Error(errors.ERR_NO_REFERENCE_NUMBER));
        }

        const combos: posModels.OrderComboRequest[] =
            state.currentOrder.comboCustomizationIds.map((id) => {
                const cc = state.customizations.combos.byInternalId[id];
                return {
                    comboId: cc.comboId,
                    quantity: cc.quantity,
                    items: cc.itemCustomizationIds.map((icid) =>
                        reduxToPosItemCustomization(
                            state.customizations.items.byInternalId[icid],
                        ),
                    ),
                    dateAdded: cc.dateAdded,
                    dateModified: cc.dateModified,
                };
            });
        const voidCombos: posModels.OrderComboRequest[] =
            state.pos.currentOrder.voidedComboIds.map((id) => {
                const vc = state.pos.currentOrderVoided.combos.voided.byId[id];
                const cc =
                    state.customizations.combos.byInternalId[
                        vc.comboCustomizationId
                    ];
                return {
                    comboId: cc.comboId,
                    quantity: vc.quantity * -1,
                    items: cc.itemCustomizationIds.map((icid) =>
                        reduxToPosItemCustomization(
                            state.customizations.items.byInternalId[icid],
                        ),
                    ),
                    dateAdded: cc.dateAdded,
                    dateModified: cc.dateModified,
                };
            });

        const items: posModels.OrderItemRequest[] =
            state.currentOrder.itemCustomizationIds.map((icid) => {
                const ic = state.customizations.items.byInternalId[icid];
                return {
                    quantity: ic.quantity,
                    customization: reduxToPosItemCustomization(
                        state.customizations.items.byInternalId[icid],
                    ),
                    dateAdded: ic.dateAdded,
                    dateModified: ic.dateModified,
                };
            });
        const voidItems: posModels.OrderItemRequest[] =
            state.pos.currentOrder.voidedItemIds.map((id) => {
                const vi = state.pos.currentOrderVoided.items.voided.byId[id];
                const ic =
                    state.customizations.items.byInternalId[
                        vi.itemCustomizationId
                    ];
                return {
                    quantity: vi.quantity * -1,
                    customization: reduxToPosItemCustomization(ic),
                    dateAdded: ic.dateAdded,
                    dateModified: ic.dateModified,
                };
            });

        const giftCards: posModels.OrderGiftCardRequest[] = [];
        // in v1, can't buy gift cards on a normal order
        if (
            items.length === 0 &&
            voidItems.length === 0 &&
            combos.length === 0 &&
            voidCombos.length === 0
        ) {
            const giftCardsToPurchase =
                state.pos.currentOrder.giftCards.purchase;
            const giftCardsToAddFunds =
                state.pos.currentOrder.giftCards.addFunds;
            if (giftCardsToPurchase.length !== 0) {
                for (const gcPurchaseInfo of giftCardsToPurchase) {
                    for (let i = 0; i < gcPurchaseInfo.quantity; i++) {
                        giftCards.push({
                            cardNumber: gcPurchaseInfo.cardNumbers?.[i] ?? "",
                            amountCents: gcPurchaseInfo.balanceCents,
                        });
                    }
                }
            }
            if (giftCardsToAddFunds.length != 0) {
                for (const gcAddFundsInfo of giftCardsToAddFunds) {
                    giftCards.push({
                        cardNumber: gcAddFundsInfo.cardNumber,
                        amountCents: gcAddFundsInfo.amountToAddCents,
                    });
                }
            }
        }

        let orderType: posModels.OrderType = "in_store";
        if (isDriveThru) {
            orderType = "drive_thru";
        } else if (state.currentOrder.orderType === "carry_out") {
            orderType = "carry_out";
        }
        if (ezCaterOrderType) {
            orderType =
                ezCaterOrderType === "pickup"
                    ? "ez_cater_pickup"
                    : "ez_cater_delivery";
        }

        let redemptionDetails: posModels.RedemptionDetail[] | null = null;
        if (state.pos.currentOrder.discounts.length) {
            let subtotalCentsWithDiscount = subtotalCents;
            redemptionDetails = state.pos.currentOrder.discounts.map(
                (discount) => {
                    let amountCents = 0;
                    if (subtotalCentsWithDiscount > 0) {
                        if (discount.amountCents) {
                            amountCents = Math.min(
                                subtotalCentsWithDiscount,
                                discount.amountCents,
                            );
                            subtotalCentsWithDiscount -= discount.amountCents;
                        } else if (discount.amountPercentage) {
                            const percentageAmount = Lib.currency.roundFloat(
                                (subtotalCents *
                                    (state.pos.currentOrder.discounts[0]
                                        .amountPercentage ?? 0)) /
                                    100.0,
                            );
                            amountCents = Math.min(
                                subtotalCentsWithDiscount,
                                percentageAmount,
                            );
                            subtotalCentsWithDiscount -= amountCents;
                        }
                    }
                    return {
                        code: discount.discountCode,
                        amountCents: amountCents,
                        type: discount.discountType,
                    };
                },
            );
        }

        let startTime = state.pos.currentOrder.startTime;
        if (!startTime) {
            logger.warn("Placing current order without valid start time");
            startTime = new Date();
        }

        const fundraiserCode = state.currentOrder.fundraiserCode;
        const qsrSelections = state.pos.currentOrder.qsrSelections;

        return orders
            .place(
                orderId,
                referenceNumber,
                deviceId ?? "",
                stationMode,
                menuId,
                null,
                guestName,
                pagerNumber,
                [...combos, ...voidCombos],
                [...items, ...voidItems],
                giftCards,
                orderType,
                charityRoundUpAmountCents,
                staffTipCents,
                subtotalCents,
                taxCents,
                totalCents,
                orderStatus,
                driveThruDetails,
                redemptionDetails,
                startTime,
                payments,
                fundraiserCode,
                qsrSelections,
            )
            .then((order) => {
                if (order.status !== "in_creation") {
                    dispatch(addOrder(order));
                    dispatch(setCurrentOrderEndTime(new Date()));
                    dispatch(setCurrentOrderOrderNumber(order.orderNumber));
                }
            });
    };
}

export function cancelOrder(orderId: string): AppThunk<Promise<void>> {
    return (dispatch) => {
        return orders.cancel(orderId).then((order) => {
            dispatch(addOrder(order));
        });
    };
}

export function removeOrder(id: string): AnyAction {
    return {
        type: posActionTypes.POS_ORDERS_REMOVE,
        id,
    };
}

export function setCurrentOrderCustomerInfo(
    guestName: string | null,
    pagerNumber: number | null,
): AnyAction {
    return {
        type: posActionTypes.POS_CURRENT_ORDER_SET_CUSTOMER_INFO,
        guestName,
        pagerNumber,
    };
}

export function setCurrentOrderStartTime(startTime: Date | null): AnyAction {
    return {
        type: posActionTypes.POS_CURRENT_ORDER_SET_START_TIME,
        startTime,
    };
}

export function setCurrentOrderEndTime(endTime: Date | null): AnyAction {
    return {
        type: posActionTypes.POS_CURRENT_ORDER_SET_END_TIME,
        endTime,
    };
}

export function setCurrentOrderDriveThruDetails(
    driveThruDetails: posModels.DriveThruDetails,
): AnyAction {
    return {
        type: posActionTypes.POS_CURRENT_ORDER_SET_DRIVE_THRU_DETAILS,
        driveThruDetails,
    };
}

export function openPendingOrder(
    order: posModels.OrderParsed,
): AppThunk<Promise<void>> {
    return (dispatch) => {
        const defaultDate = new Date();
        const items: ReduxModels.ItemCustomization[] = [];
        const combos = order.combos.map((combo) => {
            const comboCustomizationId = uuid4();

            const itemCustomizationIds = combo.items.map((item) => {
                const itemCustomizationId = uuid4();
                items.push({
                    comboCustomizationId: comboCustomizationId,
                    customizationId: itemCustomizationId,
                    itemId: item.itemId,
                    quantity: 1,
                    modifierSelections: Lib.util.modsArrayToObj(item.modifiers),
                    dateAdded: combo.dateAdded
                        ? new Date(combo.dateAdded)
                        : defaultDate, // TODO: check if this is okay since orders before these changes go live won't have dateAdded, dateModified
                    dateModified: combo.dateModified
                        ? new Date(combo.dateModified)
                        : defaultDate,
                });

                return itemCustomizationId;
            });

            return {
                customizationId: comboCustomizationId,
                comboId: combo.comboId,
                quantity: combo.quantity,
                itemCustomizationIds: itemCustomizationIds,
                dateAdded: combo.dateAdded,
                dateModified: combo.dateModified,
            };
        });

        order.items.forEach((item) => {
            items.push({
                comboCustomizationId: null,
                customizationId: uuid4(),
                itemId: item.itemId,
                quantity: item.quantity,
                modifierSelections: Lib.util.modsArrayToObj(item.modifiers),
                dateAdded: item.dateAdded
                    ? new Date(item.dateAdded)
                    : defaultDate,
                dateModified: item.dateModified
                    ? new Date(item.dateModified)
                    : defaultDate,
            });
        });

        return dispatch(
            clearCurrentOrder(
                order.storeId,
                order.type as API.models.OrderType,
            ),
        ).then(() => {
            dispatch(Actions.currentOrderActions.addItems(items, combos));
            dispatch(
                setCurrentOrderIdRefNumAndNumber(
                    order.id,
                    order.referenceNumber,
                    order.orderNumber,
                ),
            );
            dispatch(
                setCurrentOrderCustomerInfo(
                    order.guestName ?? null,
                    order.pagerNumber ?? null,
                ),
            );
            dispatch(
                setCurrentOrderDriveThruDetails(
                    order.driveThruDetails ?? {
                        vehicleType: "",
                        vehicleColor: "",
                        eatingInCar: false,
                        laneNumber: 1,
                    },
                ),
            );
            dispatch(setCurrentOrderStartTime(order.startTime));
        });
    };
}

function changeVoidedItemQuantity(id: string, quantity: number): AnyAction {
    return {
        type: posActionTypes.POS_CURRENT_VOIDED_ITEMS_CHANGE_QUANTITY,
        id,
        quantity,
    };
}

function changeVoidedComboQuantity(id: string, quantity: number): AnyAction {
    return {
        type: posActionTypes.POS_CURRENT_VOIDED_COMBOS_CHANGE_QUANTITY,
        id,
        quantity,
    };
}

export function changeCurrentOrderItemQuantity(
    customizationId: string,
    quantity: number,
): AppThunk {
    return (dispatch, getState) => {
        const state = getState();

        dispatch(
            Actions.customizationActions.changeItemQuantity(
                customizationId,
                quantity,
            ),
        );

        const voidedItemsById = state.pos.currentOrderVoided.items.voided.byId;
        const voidId = state.pos.currentOrder.voidedItemIds.find(
            (id) => voidedItemsById[id].itemCustomizationId === customizationId,
        );
        if (voidId) {
            const voidItem = voidedItemsById[voidId];
            if (voidItem.quantity > quantity) {
                dispatch(changeVoidedItemQuantity(voidId, quantity));
            }
        }
    };
}

export function changeCurrentOrderComboQuantity(
    customizationId: string,
    quantity: number,
): AppThunk {
    return (dispatch, getState) => {
        const state = getState();

        dispatch(
            Actions.customizationActions.changeComboQuantity(
                customizationId,
                quantity,
            ),
        );

        const voidedCombosById =
            state.pos.currentOrderVoided.combos.voided.byId;
        const voidId = state.pos.currentOrder.voidedComboIds.find(
            (id) =>
                voidedCombosById[id].comboCustomizationId === customizationId,
        );
        if (voidId) {
            const voidCombo = voidedCombosById[voidId];
            if (voidCombo.quantity > quantity) {
                dispatch(changeVoidedComboQuantity(voidId, quantity));
            }
        }
    };
}

export function removeCurrentOrderItem(customizationId: string): AppThunk {
    return (dispatch, getState) => {
        const state = getState();

        dispatch(Actions.currentOrderActions.removeItem(customizationId));

        const voidedItemsById = state.pos.currentOrderVoided.items.voided.byId;
        const voidId = state.pos.currentOrder.voidedItemIds.find(
            (id) => voidedItemsById[id].itemCustomizationId === customizationId,
        );
        if (voidId) {
            dispatch(removeVoidedItem(voidId));
        }
    };
}

export function removeCurrentOrderCombo(customizationId: string): AppThunk {
    return (dispatch, getState) => {
        const state = getState();

        dispatch(Actions.currentOrderActions.removeCombo(customizationId));

        const voidedCombosById =
            state.pos.currentOrderVoided.combos.voided.byId;
        const voidId = state.pos.currentOrder.voidedComboIds.find(
            (id) =>
                voidedCombosById[id].comboCustomizationId === customizationId,
        );
        if (voidId) {
            dispatch(removeVoidedCombo(voidId));
        }
    };
}

function addPendingVoidedItemImmediate(
    pendingVoidedItem: posModels.PendingVoidedItem,
): AnyAction {
    return {
        type: posActionTypes.POS_PENDING_VOIDED_ITEM_ADD,
        pendingVoidedItem,
    };
}

export function addPendingVoidedItem(
    itemCustomizationId: string,
    quantity: number,
): AppThunk {
    return (dispatch) => {
        dispatch(
            addPendingVoidedItemImmediate({
                id: uuid4(),
                itemCustomizationId,
                quantity,
            }),
        );
    };
}

function addPendingVoidedComboImmediate(
    pendingVoidedCombo: posModels.PendingVoidedCombo,
): AnyAction {
    return {
        type: posActionTypes.POS_PENDING_VOIDED_COMBO_ADD,
        pendingVoidedCombo,
    };
}

export function addPendingVoidedCombo(
    comboCustomizationId: string,
    quantity: number,
): AppThunk {
    return (dispatch) => {
        dispatch(
            addPendingVoidedComboImmediate({
                id: uuid4(),
                comboCustomizationId,
                quantity,
            }),
        );
    };
}

function addVoidedCombos(voidedCombos: posModels.VoidedCombo[]): AnyAction {
    return {
        type: posActionTypes.POS_CURRENT_VOIDED_COMBOS_ADD,
        voidedCombos,
    };
}

export function removeVoidedCombo(id: string): AnyAction {
    return {
        type: posActionTypes.POS_CURRENT_VOIDED_COMBOS_REMOVE,
        id,
    };
}

export function removeVoidedItem(id: string): AnyAction {
    return {
        type: posActionTypes.POS_CURRENT_VOIDED_ITEMS_REMOVE,
        id,
    };
}

function addVoidedItems(voidedItems: posModels.VoidedItem[]): AnyAction {
    return {
        type: posActionTypes.POS_CURRENT_VOIDED_ITEMS_ADD,
        voidedItems,
    };
}

function clearPendingVoidedItems(): AnyAction {
    return {
        type: posActionTypes.POS_PENDING_VOIDED_CLEAR,
    };
}

export function voidAllPendingItems(reason: string): AppThunk {
    return (dispatch, getState) => {
        const state = getState();

        const pendingVoidedCombosById =
            state.pos.currentOrderVoided.combos.pending.byId;
        const pendingVoidedComboIds = Object.keys(pendingVoidedCombosById);
        const voidedCombos = pendingVoidedComboIds.map((id) => ({
            ...pendingVoidedCombosById[id],
            reason,
        }));

        const pendingVoidedItemsById =
            state.pos.currentOrderVoided.items.pending.byId;
        const pendingVoidedItemIds = Object.keys(pendingVoidedItemsById);
        const voidedItems = pendingVoidedItemIds.map((id) => ({
            ...pendingVoidedItemsById[id],
            reason,
        }));

        if (voidedCombos.length) {
            dispatch(addVoidedCombos(voidedCombos));
        }

        if (voidedItems.length) {
            dispatch(addVoidedItems(voidedItems));
        }

        dispatch(clearPendingVoidedItems());
    };
}

export function removePendingVoidedItem(id: string): AnyAction {
    return {
        type: posActionTypes.POS_PENDING_VOIDED_ITEM_REMOVE,
        id,
    };
}

export function removePendingVoidedCombo(id: string): AnyAction {
    return {
        type: posActionTypes.POS_PENDING_VOIDED_COMBO_REMOVE,
        id,
    };
}

export function changePendingVoidedItemQuantity(
    id: string,
    quantity: number,
): AnyAction {
    return {
        type: posActionTypes.POS_PENDING_VOIDED_ITEM_CHANGE_QUANTITY,
        id,
        quantity,
    };
}

export function changePendingVoidedComboQuantity(
    id: string,
    quantity: number,
): AnyAction {
    return {
        type: posActionTypes.POS_PENDING_VOIDED_COMBO_CHANGE_QUANTITY,
        id,
        quantity,
    };
}

export function voidEntireOrder(): AppThunk {
    return (dispatch, getState) => {
        const state = getState();

        const pendingVoidedCombosById =
            state.pos.currentOrderVoided.combos.pending.byId;
        const pendingVoidedComboIds = Object.keys(pendingVoidedCombosById);
        const currentOrderComboCustomizationIds =
            state.currentOrder.comboCustomizationIds;
        currentOrderComboCustomizationIds.forEach((id) => {
            const pendingComboId = pendingVoidedComboIds.find(
                (comboId) =>
                    pendingVoidedCombosById[comboId].comboCustomizationId ===
                    id,
            );
            const customization = state.customizations.combos.byInternalId[id];
            if (pendingComboId) {
                // change quantity of existing pending-voided-combo if it doesn't match current order combo quantity
                if (
                    pendingVoidedCombosById[pendingComboId].quantity !==
                    customization.quantity
                ) {
                    dispatch(
                        changePendingVoidedComboQuantity(
                            pendingComboId,
                            customization.quantity,
                        ),
                    );
                }
            } else {
                dispatch(addPendingVoidedCombo(id, customization.quantity));
            }
        });

        const pendingVoidedItemsById =
            state.pos.currentOrderVoided.items.pending.byId;
        const pendingVoidedItemIds = Object.keys(pendingVoidedItemsById);
        const currentOrderItemCustomizationIds =
            state.currentOrder.itemCustomizationIds;
        currentOrderItemCustomizationIds.forEach((id) => {
            const pendingItemId = pendingVoidedItemIds.find(
                (itemId) =>
                    pendingVoidedItemsById[itemId].itemCustomizationId === id,
            );
            const customization = state.customizations.items.byInternalId[id];
            if (pendingItemId) {
                // change quantity of existing pending-voided-item if it doesn't match current order item quantity
                if (
                    pendingVoidedItemsById[pendingItemId].quantity !==
                    customization.quantity
                ) {
                    dispatch(
                        changePendingVoidedItemQuantity(
                            pendingItemId,
                            customization.quantity,
                        ),
                    );
                }
            } else {
                dispatch(addPendingVoidedItem(id, customization.quantity));
            }
        });
    };
}

export function currentOrderRemoveDiscount(discountCode: string): AnyAction {
    return {
        type: posActionTypes.POS_CURRENT_ORDER_REMOVE_DISCOUNT,
        discountCode,
    };
}

export function currentOrderAddDiscount(
    discountCode: string,
    amountCents: API.models.USDCents | null,
    amountPercentage: number | null,
    discountType: posModels.DiscountType | null,
    name?: string,
    employeeId?: string,
    reason?: string,
): AnyAction {
    return {
        type: posActionTypes.POS_CURRENT_ORDER_ADD_DISCOUNT,
        name,
        discountCode,
        amountCents,
        amountPercentage,
        discountType,
        employeeId,
        reason,
    };
}

function getItemDiscountInfo(
    itemCustomizationIds: string[],
    itemCustomizations: {
        byInternalId: {
            [internalId: string]: ReduxModels.ItemCustomization;
        };
    },
    menuId: string | null,
    menuItemModifiersbyId: {
        [menuId: string]: {
            [itemId: string]: {
                [modifierId: string]: MenuItemModifier;
            };
        };
    },
    allPrepsModsById: {
        byId: {
            [id: string]: Lib.selectors.ItemModifierData;
        };
    },
) {
    if (!menuId) {
        return [];
    }

    const menuItemModifiers = menuItemModifiersbyId[menuId];

    const items: posModels.ItemDiscountInfo[] = itemCustomizationIds.map(
        (icId) => {
            const itemCustomization = itemCustomizations.byInternalId[icId];
            const itemId = itemCustomization.itemId;
            const quantity = itemCustomization.quantity;
            const modifierSelections = itemCustomization.modifierSelections;
            return {
                quantity: quantity,
                customization: {
                    itemId: itemId,
                    modifiers: Object.keys(modifierSelections).map(
                        (modifierId) => {
                            const menuItemModifier =
                                menuItemModifiers[itemCustomization.itemId][
                                    modifierId
                                ];
                            let modSelection:
                                | API.models.MenuItemModifierSelection
                                | Lib.selectors.ItemModifierSelectionData
                                | undefined = undefined;

                            if (menuItemModifier) {
                                const [selection] =
                                    menuItemModifier.selections.filter(
                                        (x) =>
                                            x.id ===
                                            modifierSelections[modifierId]
                                                .selectionId,
                                    );
                                modSelection = selection;
                            }

                            if (!modSelection) {
                                const allPrepMod =
                                    allPrepsModsById.byId[modifierId];
                                const allPrepModSelection =
                                    allPrepMod.selections.find(
                                        (selection) =>
                                            selection.id ===
                                            itemCustomization
                                                .modifierSelections[modifierId]
                                                ?.selectionId,
                                    );
                                modSelection = allPrepModSelection;
                            }

                            if (!modSelection) {
                                throw new Error(
                                    `modifier selection for modifier id: ${modifierId} on item could not be found`,
                                );
                            }

                            return {
                                modifierId: modifierId,
                                modifierSelectionId: modSelection.id,
                                quantity: quantity,
                            };
                        },
                    ),
                },
            };
        },
    );

    return items;
}

function getComboDiscountInfo(
    currentOrder: CurrentOrderState,
    comboCustomizations: {
        byInternalId: {
            [internalId: string]: ReduxModels.ComboCustomization;
        };
    },
    itemCustomizations: {
        byInternalId: {
            [internalId: string]: ReduxModels.ItemCustomization;
        };
    },
    menuId: string | null,
    menuItemModifiersbyId: {
        [menuId: string]: {
            [itemId: string]: {
                [modifierId: string]: MenuItemModifier;
            };
        };
    },
    allPrepsModsById: {
        byId: {
            [id: string]: Lib.selectors.ItemModifierData;
        };
    },
) {
    if (!menuId) {
        return [];
    }

    const combos: posModels.ComboDiscountInfo[] =
        currentOrder.comboCustomizationIds.map((ccId) => {
            const comboCustomization = comboCustomizations.byInternalId[ccId];
            const itemCustomizationIds =
                comboCustomization.itemCustomizationIds;
            const comboId = comboCustomization.comboId;
            const quantity = comboCustomization.quantity;
            const items = getItemDiscountInfo(
                itemCustomizationIds,
                itemCustomizations,
                menuId,
                menuItemModifiersbyId,
                allPrepsModsById,
            );
            const comboItems = items.map(
                (itemDiscountInfo) => itemDiscountInfo.customization,
            );

            return {
                comboId: comboId,
                quantity: quantity,
                items: comboItems,
            };
        });

    return combos;
}

export function currentOrderValidateDiscount(
    discountCode: string,
    employeeId?: string,
    reason?: string,
    ignoreExistingDiscountType = false,
): AppThunk<Promise<posModels.DiscountResponse>> {
    return async (dispatch, getState) => {
        const state = getState();
        const currentOrder = state.currentOrder;
        const orderId = state.pos.currentOrder.id;
        const comboCustomizations = state.customizations.combos;
        const itemCustomizations = state.customizations.items;
        const menuId = state.currentOrder.menuId;
        const menuItemModifiersbyId = state.menuItemModifiers.byMenuId;
        const allPrepsMods = state.pos.allPreps;

        if (!orderId || !menuId) {
            throw new Error(
                `invalid current order id: ${orderId} and/or invalid menuId: ${menuId}`,
            );
        }

        const combos = getComboDiscountInfo(
            currentOrder,
            comboCustomizations,
            itemCustomizations,
            menuId,
            menuItemModifiersbyId,
            allPrepsMods,
        );
        const items = getItemDiscountInfo(
            currentOrder.itemCustomizationIds,
            itemCustomizations,
            menuId,
            menuItemModifiersbyId,
            allPrepsMods,
        );

        if (combos.length === 0 && items.length === 0) {
            // cart is empty, throw error to remove discount
            throw new Error(errors.NO_DISCOUNTS_CART_EMPTY);
        }

        let existingDiscountType = undefined;
        const currentOrderDiscounts = state.pos.currentOrder.discounts;
        if (!ignoreExistingDiscountType && currentOrderDiscounts.length > 0) {
            // either every discount in the list is a tracks discount or the length of current order discount is 1. we can just use the type of the first discount in the list
            existingDiscountType = currentOrderDiscounts[0].discountType;
        }

        const isDuplicate = !!currentOrderDiscounts.find(
            (d) => d.discountCode === discountCode,
        );

        return discountMethods
            .validate(
                discountCode,
                combos,
                items,
                orderId,
                menuId,
                employeeId,
                reason,
                existingDiscountType,
                isDuplicate,
            )
            .then((response) => {
                return response;
            });
    };
}

export function addExistingGiftCardRequest(
    cardNumber: string,
    originalBalanceCents: API.models.USDCents,
    amountToAddCents: API.models.USDCents,
): AnyAction {
    return {
        type: posActionTypes.POS_CURRENT_ORDER_ADD_EXISTING_GIFT_CARD_REQUEST,
        id: uuid4(),
        cardNumber,
        originalBalanceCents,
        amountToAddCents,
    };
}

export function updateExistingGiftCardRequest(
    id: string,
    amountToAddCents: API.models.USDCents,
): AnyAction {
    return {
        type: posActionTypes.POS_CURRENT_ORDER_UPDATE_EXISTING_GIFT_CARD_REQUEST,
        id,
        amountToAddCents,
    };
}

export function removeExistingGiftCardRequest(id: string): AnyAction {
    return {
        type: posActionTypes.POS_CURRENT_ORDER_REMOVE_EXISTING_GIFT_CARD_REQUEST,
        id,
    };
}

export function submitExistingGiftCardRequests(): AppThunk<
    Promise<posModels.GiftCardsIncreaseBalanceResponse>
> {
    return (dispatch, getState) => {
        const state = getState();
        const orderId = state.pos.currentOrder.id;
        if (!orderId) {
            throw new Error("Current order ID is null");
        }

        const existingCards = state.pos.currentOrder.giftCards.addFunds;
        return paymentMethods.increaseGiftCardBalance(
            orderId,
            existingCards.map((gc) => ({
                cardNumber: gc.cardNumber,
                amountCents: gc.amountToAddCents,
            })),
        );
    };
}

export function addNewGiftCardRequest(
    balanceCents: API.models.USDCents,
    quantity: number,
): AnyAction {
    return {
        type: posActionTypes.POS_CURRENT_ORDER_ADD_NEW_GIFT_CARD_REQUEST,
        id: uuid4(),
        balanceCents,
        quantity,
    };
}

export function updateNewGiftCardRequest(
    id: string,
    balanceCents: API.models.USDCents,
    quantity: number,
): AnyAction {
    return {
        type: posActionTypes.POS_CURRENT_ORDER_UPDATE_NEW_GIFT_CARD_REQUEST,
        id,
        balanceCents,
        quantity,
    };
}

export function removeNewGiftCardRequest(id: string): AnyAction {
    return {
        type: posActionTypes.POS_CURRENT_ORDER_REMOVE_NEW_GIFT_CARD_REQUEST,
        id,
    };
}

export function currentOrderSetQsrSelections(
    selections: posModels.QsrSelection[],
) {
    return {
        type: posActionTypes.POS_CURRENT_ORDER_SET_QSR_SELECTIONS,
        selections,
    };
}

export function requestCCPayment(
    stationMode: posModels.StationMode, // should be one of front_of_house_1, front_of_house_2, drive_thru_order_fulfillment
    taxCents: API.models.USDCents,
    totalCents: API.models.USDCents,
): AppThunk<Promise<posModels.ProcessPaymentResponse>> {
    return async (dispatch, getState) => {
        const state = getState();
        const posCurrentOrder = state.pos.currentOrder;
        const referenceNumber = posCurrentOrder.referenceNumber;
        if (referenceNumber === null) {
            throw new Error("invalid reference number on current order");
        }

        // TODO: might have to change this once we allow gc purchasing with regular orders
        const hideTipsPrompt =
            posCurrentOrder.giftCards.purchase.length > 0 ||
            posCurrentOrder.giftCards.addFunds.length > 0;

        // TODO: this might need to change as we convert to doing computation with dollar strings.
        return ccMethods
            .processPayment(
                stationMode,
                referenceNumber,
                referenceNumber,
                referenceNumber.toString(),
                totalCents,
                taxCents,
                hideTipsPrompt,
            )
            .then((response) => {
                return response;
            });
    };
}

export function setPaymentCashPaidCents(
    amountCents: API.models.USDCents,
): AnyAction {
    return {
        type: posActionTypes.POS_PAYMENT_SET_CASH_PAID_CENTS,
        amountCents,
    };
}

export function addToPaymentCashPaidCents(
    amountCents: API.models.USDCents,
): AnyAction {
    return {
        type: posActionTypes.POS_PAYMENT_ADD_TO_CASH_PAID_CENTS,
        amountCents,
    };
}

export function setPaymentChangeOwedCents(
    amountCents: API.models.USDCents,
): AnyAction {
    return {
        type: posActionTypes.POS_PAYMENT_SET_CHANGE_OWED_CENTS,
        amountCents,
    };
}

export function applyPaymentGiftCard(
    giftCard: posModels.AppliedGiftCard,
): AnyAction {
    return {
        type: posActionTypes.POS_PAYMENT_APPLY_GIFT_CARD,
        giftCard,
    };
}

export function removePaymentGiftCard(id: string): AnyAction {
    return {
        type: posActionTypes.POS_PAYMENT_REMOVE_GIFT_CARD,
        id,
    };
}

export function setPaymentGiftCards(
    giftCardsApplied: posModels.AppliedGiftCard[],
): AnyAction {
    return {
        type: posActionTypes.POS_PAYMENT_SET_GIFT_CARDS,
        giftCardsApplied,
    };
}

export function applyPaymentCompCardImmediate(
    compCard: posModels.AppliedCompCard,
): AnyAction {
    return {
        type: posActionTypes.POS_PAYMENT_APPLY_COMP_CARD,
        compCard,
    };
}

export function applyPaymentCompCard(
    compCard: posModels.AppliedCompCard,
    remainingBalanceOnceApplied: number,
): AppThunk {
    return (dispatch, getState) => {
        const state = getState();
        const currentGiftCardsApplied =
            state.pos.currentOrder.payment.giftCardsApplied;
        if (remainingBalanceOnceApplied <= 0) {
            dispatch(setPaymentGiftCards([]));
        } else {
            const newAppliedGiftCards: posModels.AppliedGiftCard[] = [];
            let remainingBalance = remainingBalanceOnceApplied;
            for (const appliedGiftCard of currentGiftCardsApplied) {
                const appliedCents = Math.min(
                    appliedGiftCard.appliedCents,
                    remainingBalance,
                );
                const newAppliedGiftCard = {
                    ...appliedGiftCard,
                    appliedCents: appliedCents,
                };
                newAppliedGiftCards.push(newAppliedGiftCard);
                remainingBalance -= appliedCents;
                if (remainingBalance <= 0) {
                    break;
                }
            }
            dispatch(setPaymentGiftCards(newAppliedGiftCards));
        }
        dispatch(applyPaymentCompCardImmediate(compCard));
    };
}

export function removePaymentCompCard(id: string): AnyAction {
    return {
        type: posActionTypes.POS_PAYMENT_REMOVE_COMP_CARD,
        id,
    };
}

export function applyPaymentCreditCard(
    creditCard: posModels.AppliedCreditCard,
): AnyAction {
    return {
        type: posActionTypes.POS_PAYMENT_ADD_CREDIT_CARD,
        creditCard,
    };
}

export function applyEzCater(): AnyAction {
    return {
        type: posActionTypes.POS_PAYMENT_APPLY_EZ_CATER,
    };
}

export function clearPayments(): AnyAction {
    return {
        type: posActionTypes.POS_PAYMENT_CLEAR,
    };
}

export function currentOrderSetCharityAmountCents(
    charityAmountCents: API.models.USDCents | null,
): AnyAction {
    return {
        type: posActionTypes.POS_CURRENT_ORDER_SET_CHARITY_AMOUNT_CENTS,
        charityAmountCents,
    };
}
