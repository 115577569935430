import MenuCategories from "../MenuCategories";
import React, {useContext} from "react";
import styles from "./Menu.module.css";
import {Outlet} from "react-router-dom";
import {CashierModeContext} from "../CashierModeContext";
import {TillAssignmentContext} from "../TillAssignmentContext";
import {useAppSelector} from "../../lib/hooks";

export default function Menu() {
    // TODO: #261 - support purchasing gift cards as part of regular order
    const isGiftCardsOrder = useAppSelector(
        (state) =>
            !!state.pos.currentOrder.giftCards.addFunds.length ||
            !!state.pos.currentOrder.giftCards.purchase.length,
    );
    const {isTraining} = useContext(CashierModeContext);
    const {assignedTill, trainingModeAssignedTill, tillAssignmentSkipped} =
        useContext(TillAssignmentContext);
    const isTillAssignmentRequired = isTraining
        ? trainingModeAssignedTill === -1
        : assignedTill === -1 && !tillAssignmentSkipped;

    return (
        <>
            <MenuCategories
                className={styles.menuCategories}
                disabled={isTillAssignmentRequired || isGiftCardsOrder}
            />
            <div className={styles.menuCategoryItems}>
                <Outlet />
            </div>
        </>
    );
}
