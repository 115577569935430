import * as posActions from "../../lib/posActions";
import * as posSelectors from "../../lib/posSelectors";
import * as routeHelpers from "../../lib/routeHelpers";
import * as searchParamNames from "../../constants/searchParamNames";
import CashierCheckoutItems from "./CashierCheckoutItems";
import CashierCheckoutOrders from "./CashierCheckoutOrders";
import cn from "classnames";
import describeNetworkError from "../../lib/describeNetworkError";
import ErrorModal from "../ErrorModal";
import logger from "../../lib/logger";
import managerIconBlue from "../../images/m-circle-blue.svg";
import managerIconWhite from "../../images/m-circle-white.svg";
import OutlineButton from "../OutlineButton";
import React, {useState, useContext, useMemo, useRef} from "react";
import Spinner from "../Spinner";
import styles from "./CashierCheckout.module.css";
import TextButton from "../TextButton";
import TextModal from "../TextModal";
import TimeSince from "../TimeSince";
import {CashierModeContext} from "../CashierModeContext";
import {Lib} from "habit-core";
import {
    NavLink,
    Routes,
    Route,
    useNavigate,
    useLocation,
    useSearchParams,
} from "react-router-dom";
import {useAppSelector, useAppDispatch} from "../../lib/hooks";
import {TillAssignmentContext} from "../TillAssignmentContext";
import useCurrentTime from "../../lib/useCurrentTime";
import useUpdateBackendOrderContents from "../../lib/useUpdateBackendOrderContents";

const strings = {
    orderNumber: "Order #",
    currentOrder: "Current Order: ",
    noCurrentOrder: "No Current Order",
    pendingOrders: "Pending Orders :",
    managerMode: "Register Is In Management Mode",
    managerName: "Manager :",
    exit: "Exit",
    trainingMode: "Register Is In Training Mode",
    endTraining: "End Training",
    voidOrder: "Void Entire Order",
    orderPlaced: "Order Placed",
    confirm: "Place Order",
    confirmText: "Please confirm you want to place this order",
    giftCards: "Gift Cards",
};

type Props = {
    className?: string;
};

export default function CashierCheckout(props: Props) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const isRequestingCCPayment =
        searchParams.get(searchParamNames.REQUESTING_CC_PAYMENT) === "true";
    const location = useLocation();
    const {time} = useCurrentTime();

    const orderData = useAppSelector((state) =>
        Lib.selectors.getMyOrderData(state, "date_added"),
    );

    const {assignedTill, trainingModeAssignedTill, tillAssignmentSkipped} =
        useContext(TillAssignmentContext);
    const posCurrentOrder = useAppSelector((state) => state.pos.currentOrder);
    const subtotalCents = useAppSelector((state) =>
        posSelectors.getCurrentOrderSubtotalSansVoidedCents(state),
    );
    const charityRoundUpAmountCents = useAppSelector((state) =>
        posSelectors.getCharityRoundUpSansVoidedCents(state, []),
    );
    const taxCents = useAppSelector((state) =>
        posSelectors.getCurrentOrderTaxSansVoidedCents(state, []),
    );

    const currentOrderTotalCents =
        subtotalCents + charityRoundUpAmountCents + taxCents;
    const ordersById = useAppSelector((state) => state.pos.orders.byId);
    const numPendingOrders = Object.keys(ordersById).reduce(
        (sum, id) => (ordersById[id].status === "pending" ? sum + 1 : sum),
        0,
    );
    const storeId = useAppSelector((state) => state.currentOrder.storeId);
    const stationMode = useAppSelector((state) => state.pos.station.mode);

    /* if the station mode is drive_thru_order_taker, we want to update the backend order record any time the order contents change */
    useUpdateBackendOrderContents(
        stationMode === "drive_thru_order_taker" && !!posCurrentOrder.id,
    );

    const {
        cashierMode,
        setCashierMode,
        isTraining,
        toggleIsTraining,
        errorAfterPaymentsProcessed,
    } = useContext(CashierModeContext);

    const getIsEntireOrderVoided = useMemo(
        () => posSelectors.createGetIsEntireOrderVoided(),
        [],
    );
    const isEntireOrderVoided = useAppSelector((state) =>
        getIsEntireOrderVoided(state),
    );

    const [isConfirmingSubmitDriveThru, setIsConfirmingSubmitDriveThru] =
        useState(false);
    const [isSubmittingDriveThruOrder, setIsSubmittingDriveThruOrder] =
        useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [preNavToManagerPath, setPreNavToManagerPath] = useState("");

    const isManagerPage = location.pathname.includes("manager");
    const isManagerVoidPage = location.pathname === routeHelpers.voidOrder();
    const disableManagerButton =
        location.pathname === routeHelpers.giftCardPurchase() ||
        isRequestingCCPayment ||
        errorAfterPaymentsProcessed;
    const managerButtonRef = useRef<HTMLButtonElement>(null);

    const isTillAssignmentRequired = isTraining
        ? trainingModeAssignedTill === -1
        : assignedTill === -1 && !tillAssignmentSkipped;

    const disableGiftCardLink =
        location.pathname === routeHelpers.giftCardPurchase() ||
        isTillAssignmentRequired ||
        orderData.length ||
        isRequestingCCPayment ||
        errorAfterPaymentsProcessed;

    const submitDriveThruOrder = () => {
        if (!storeId || isSubmittingDriveThruOrder) {
            return;
        }

        setIsSubmittingDriveThruOrder(true);
        dispatch(
            posActions.placeCurrentOrder(
                null,
                null,
                true,
                charityRoundUpAmountCents,
                subtotalCents,
                null,
                taxCents,
                currentOrderTotalCents,
                "pending",
                null,
                null,
            ),
        )
            .then(() => {
                setIsConfirmingSubmitDriveThru(false);
                setIsSubmittingDriveThruOrder(false);
                navigate(routeHelpers.orderComplete(), {
                    state: {
                        header: strings.orderPlaced,
                    },
                });
            })
            .catch((err) => {
                logger.warn(err);
                setIsConfirmingSubmitDriveThru(false);
                setIsSubmittingDriveThruOrder(false);
                setErrorMessage(describeNetworkError(err).join("\n"));
            });
    };

    const now = new Date();
    function getOrderEndTime() {
        if (!posCurrentOrder.startTime) {
            return now;
        }
        return posCurrentOrder.endTime ?? undefined;
    }

    function getOrderHeaderText() {
        if (posCurrentOrder.id && posCurrentOrder.orderNumber) {
            return `${strings.orderNumber} ${posCurrentOrder.orderNumber}`;
        } else if (posCurrentOrder.id) {
            return strings.currentOrder;
        } else {
            return strings.noCurrentOrder;
        }
    }

    function handleManagerFunctionsExit() {
        if (preNavToManagerPath === routeHelpers.deviceSetup()) {
            navigate(routeHelpers.deviceSetup(), {
                state: {
                    skipCCRegistrationCheck:
                        stationMode === "drive_thru_order_taker",
                },
            });
        } else if (stationMode === "drive_thru_order_fulfillment") {
            navigate(
                posCurrentOrder.id
                    ? routeHelpers.otherOrders()
                    : routeHelpers.checkout(),
            );
        } else {
            navigate(routeHelpers.menu());
        }
    }

    return (
        <>
            <div className={cn(styles.container, props.className)}>
                <div className={styles.headerContainer}>
                    <div className={styles.headerOrderContainer}>
                        <div className={styles.currentTimeContainer}>
                            {time}
                        </div>
                        <div className={styles.headerOrderContainerRow}>
                            <div className={styles.headerOrderText}>
                                {getOrderHeaderText()}
                            </div>
                            <TimeSince
                                startTime={posCurrentOrder.startTime ?? now}
                                endTime={getOrderEndTime()}
                            />
                        </div>
                        {stationMode === "drive_thru_order_fulfillment" ? (
                            <TextButton
                                className={styles.headerOrderButton}
                                label={`${strings.pendingOrders} ${numPendingOrders}`}
                                disabled={
                                    location.pathname ===
                                        routeHelpers.orderComplete() ||
                                    isTillAssignmentRequired
                                }
                                onClick={() => {
                                    if (
                                        location.pathname !==
                                        routeHelpers.checkout()
                                    ) {
                                        navigate(
                                            posCurrentOrder.id
                                                ? routeHelpers.otherOrders()
                                                : routeHelpers.checkout(),
                                        );
                                    }
                                }}
                            />
                        ) : null}
                    </div>
                    <NavLink
                        className={({isActive}) =>
                            cn({
                                [styles.giftCardsLink]: true,
                                [styles.giftCardsLinkActive]: isActive,
                                [styles.giftCardsLinkDisabled]:
                                    // TODO: #261 - support purchasing gift cards as part of regular order
                                    disableGiftCardLink,
                            })
                        }
                        to={routeHelpers.giftCards()}>
                        <div>{strings.giftCards}</div>
                    </NavLink>
                    <button
                        ref={managerButtonRef}
                        className={cn({
                            [styles.managerButton]: true,
                            [styles.managerButtonActive]: isManagerPage,
                            [styles.managerButtonDisabled]:
                                disableManagerButton,
                        })}
                        onClick={() => {
                            if (location.pathname.indexOf("manager") !== -1) {
                                handleManagerFunctionsExit();
                            } else {
                                setPreNavToManagerPath(location.pathname);
                                navigate(routeHelpers.managerMenu());
                            }
                            if (managerButtonRef.current) {
                                managerButtonRef.current.blur();
                            }
                        }}
                        disabled={disableManagerButton}>
                        <img
                            src={
                                isManagerPage
                                    ? managerIconWhite
                                    : managerIconBlue
                            }
                            alt=""
                        />
                    </button>
                </div>

                {cashierMode.type === "manager" ? (
                    <div className={styles.managerBanner}>
                        <div>
                            <div className={styles.managerModeLabel}>
                                {strings.managerMode}
                            </div>
                            <div className={styles.managerName}>
                                {strings.managerName} {cashierMode.firstName}{" "}
                                {cashierMode.lastName}
                            </div>
                        </div>
                        <OutlineButton
                            className={styles.exitManagerMode}
                            label={strings.exit}
                            onClick={() => {
                                setCashierMode({type: "user"});
                                if (
                                    location.pathname.indexOf("manager") !== -1
                                ) {
                                    handleManagerFunctionsExit();
                                }
                            }}
                        />
                    </div>
                ) : null}

                {isTraining ? (
                    <div className={styles.trainingBanner}>
                        <div>{strings.trainingMode}</div>
                        <OutlineButton
                            className={styles.exitTrainingMode}
                            label={strings.endTraining}
                            onClick={() => toggleIsTraining(false)}
                        />
                    </div>
                ) : null}

                {isManagerVoidPage ? (
                    <div className={styles.voidOrderContainer}>
                        <OutlineButton
                            disabled={!orderData.length || isEntireOrderVoided}
                            className={styles.voidOrderButton}
                            label={strings.voidOrder}
                            onClick={() =>
                                dispatch(posActions.voidEntireOrder())
                            }
                        />
                    </div>
                ) : null}

                <Routes>
                    <Route
                        path="*"
                        element={
                            <CashierCheckoutItems
                                className={cn({
                                    [styles.dataContainer]: true,
                                    [styles.voidContainer]: isManagerVoidPage,
                                })}
                                submitDriveThruOrder={
                                    stationMode ===
                                    "drive_thru_order_fulfillment"
                                        ? submitDriveThruOrder
                                        : () =>
                                              setIsConfirmingSubmitDriveThru(
                                                  true,
                                              )
                                }
                                orderData={orderData}
                            />
                        }
                    />

                    {stationMode === "drive_thru_order_fulfillment" ? (
                        <Route
                            path="/checkout/step-1"
                            element={
                                <CashierCheckoutOrders
                                    className={styles.dataContainer}
                                />
                            }
                        />
                    ) : null}

                    <Route
                        path="/checkout/other-orders"
                        element={
                            <CashierCheckoutOrders
                                className={styles.dataContainer}
                            />
                        }
                    />
                </Routes>
            </div>
            {isSubmittingDriveThruOrder ? (
                <div className={styles.spinnerContainer}>
                    <Spinner />
                </div>
            ) : null}
            {isConfirmingSubmitDriveThru ? (
                <TextModal
                    onClose={() => setIsConfirmingSubmitDriveThru(false)}
                    title={strings.confirm}
                    content={strings.confirmText}
                    onSubmit={submitDriveThruOrder}
                    submitLoading={isSubmittingDriveThruOrder}
                />
            ) : null}
            {errorMessage ? (
                <ErrorModal
                    errorMessage={errorMessage}
                    onClose={() => setErrorMessage("")}
                    showITInfo
                />
            ) : null}
        </>
    );
}
