import * as userPosMethods from "../../lib/api/users";
import * as models from "../../lib/api/posModels";
import * as routeHelpers from "../../lib/routeHelpers";
import cardErrorIcon from "../../images/manager-card-error.svg";
import cn from "classnames";
import ErrorModal from "../ErrorModal";
import OutlineButton from "../OutlineButton";
import React, {FormEvent, useContext, useEffect, useRef, useState} from "react";
import Spinner from "../Spinner";
import styles from "./ManagerAssignCard.module.css";
import swipeCardIcon from "../../images/swipe-card.svg";
import TextInput from "../TextInput";
import {useExternalInput} from "../../lib/api/useExternalInput";
import StyledKeyboard from "../StyledKeyboard";
import {useNavigate} from "react-router-dom";
import {CashierModeContext} from "../CashierModeContext";

const strings = {
    employeeIdPlaceholder: "Employee ID",
    continue: "Continue",
    return: "Return To Previous Screen",
    swipeInstruction: "Swipe New Manager's ID Card",
    managerName: "Manager's Name",
    employeeId: "Employee ID",
    addManager: "Add New Manager",
    addManagerAdditional: "Please enter the employee ID of the New Manager",
    cardAssignError: "Error Assigning Card!",
    cardAssignSuccess: "Card Successfully Assigned!",
    unexpectedError: "An unexpected error occured. Please try again.",
    managerNotFound:
        "The code entered does not belong to a manager for this store. Please check the code and try again",
};

const SHOW_SWIPE_BUTTON = import.meta.env.REACT_APP_MOCK_API === "true";

export default function ManagerAssignCard() {
    const navigate = useNavigate();
    const {setCashierMode} = useContext(CashierModeContext);

    const [employeeId, setEmployeeId] = useState("");
    const [employeeError, setEmployeeError] = useState("");
    const [employee, setEmployee] = useState<models.Manager | null>(null);
    const [showErrorScreen, setShowErrorScreen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [cardReaderEnabled, setCardReaderEnabled] = useState(false);
    const {
        externalInput: cardNumber,
        clearExternalInput: clearCardNumber,
        cardReadError,
    } = useExternalInput(cardReaderEnabled);

    /* keyboard */
    const [keyboardCollapsed, setKeyboardCollapsed] = useState(true);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current && !keyboardCollapsed) {
            if (document.activeElement === inputRef.current) {
                inputRef?.current?.scrollIntoView();
            }
        }
    }, [keyboardCollapsed]);

    useEffect(() => {
        if (employee && !showErrorScreen) {
            setCardReaderEnabled(true);
        } else {
            setCardReaderEnabled(false);
        }
    }, [employee, showErrorScreen]);

    useEffect(() => {
        if (cardNumber.length === 10) {
            handleCardSwipe();
        }
    }, [cardNumber]);

    const resetEmployeeInfo = () => {
        setEmployeeId("");
        setEmployee(null);
    };

    const resetCardErrorScreen = () => {
        setShowErrorScreen(false);
    };

    const handleGetEmployee = () => {
        setLoading(true);
        userPosMethods
            .validateManagerById(employeeId)
            .then((employeeInfo) => {
                setEmployee(employeeInfo);
            })
            .catch((err) => {
                if (err.response.status === 400) {
                    setEmployeeError(strings.managerNotFound);
                } else {
                    setEmployeeError(strings.unexpectedError);
                }
                setEmployeeId("");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleGetEmployeeForm = (e: FormEvent) => {
        e.preventDefault();
        handleGetEmployee();
    };

    const handleCardSwipe = () => {
        setLoading(true);
        userPosMethods
            .associateCard(employeeId, cardNumber)
            .then(() => {
                navigate(routeHelpers.menu());
                setCashierMode({type: "user"});
            })
            .catch(() => {
                setShowErrorScreen(true);
                clearCardNumber();
            })
            .finally(() => setLoading(false));
    };

    function renderEmployeeInputScreen() {
        return (
            <>
                <div className={styles.mainMessage}>{strings.addManager}</div>
                <div className={styles.additionalMessage}>
                    {strings.addManagerAdditional}
                </div>
                <form
                    className={styles.formContainer}
                    onSubmit={handleGetEmployeeForm}>
                    {!loading ? (
                        <>
                            <TextInput
                                value={employeeId}
                                onChange={(e) => setEmployeeId(e.target.value)}
                                onFocus={() => setKeyboardCollapsed(false)}
                                placeholder={strings.employeeIdPlaceholder}
                                className={styles.employeeNumberInput}
                                required={true}
                                inputMode="none"
                                ref={inputRef}
                            />
                            {employeeError ? (
                                <div className={styles.errorMessage}>
                                    {employeeError}
                                </div>
                            ) : null}
                        </>
                    ) : (
                        <div className={styles.spinnerContainer}>
                            <Spinner />
                        </div>
                    )}
                    <OutlineButton
                        label={strings.continue}
                        className={styles.continueButton}
                        type="submit"
                        disabled={loading}
                    />
                </form>
            </>
        );
    }

    function renderCardSwipeScreen() {
        return (
            <>
                <img className={styles.icon} src={swipeCardIcon} alt="" />
                <div className={styles.mainMessage}>
                    {strings.swipeInstruction}
                </div>
                {!loading ? (
                    <div className={styles.infoContainer}>
                        <div className={styles.info}>
                            <div className={styles.infoLabel}>
                                {strings.managerName}
                            </div>
                            <div className={styles.infoValue}>
                                {`${employee?.firstName} ${employee?.lastName}`}
                            </div>
                        </div>
                        <div className={styles.info}>
                            <div className={styles.infoLabel}>
                                {strings.employeeId}
                            </div>
                            <div className={styles.infoValue}>{employeeId}</div>
                        </div>
                    </div>
                ) : (
                    <div className={styles.spinnerContainer}>
                        <Spinner />
                    </div>
                )}
                <OutlineButton
                    label={strings.return}
                    className={styles.returnButton}
                    onClick={resetEmployeeInfo}
                    disabled={loading}
                />
                {SHOW_SWIPE_BUTTON ? (
                    <OutlineButton
                        className={styles.swipeButton}
                        label="swipe card"
                        onClick={() => {
                            handleCardSwipe();
                        }}
                    />
                ) : null}
            </>
        );
    }

    function renderCardSwipeErrorScreen() {
        return (
            <>
                <img className={styles.errorIcon} src={cardErrorIcon} alt="" />
                <div className={cn(styles.mainMessage, styles.error)}>
                    {strings.cardAssignError}
                </div>
                <OutlineButton
                    label={strings.return}
                    className={styles.returnButton}
                    onClick={resetCardErrorScreen}
                />
            </>
        );
    }

    let content: React.ReactNode = null;
    if (!employee) {
        content = renderEmployeeInputScreen();
    } else if (!showErrorScreen) {
        content = renderCardSwipeScreen();
    } else {
        content = renderCardSwipeErrorScreen();
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.contentContainer}>{content}</div>

                <div
                    className={cn(
                        styles.keyboardContainer,
                        keyboardCollapsed && styles.keyboardContainerCollapsed,
                    )}>
                    <StyledKeyboard
                        currentInput={employeeId}
                        visible={!keyboardCollapsed}
                        setVisible={(val: boolean) =>
                            setKeyboardCollapsed(!val)
                        }
                        onChange={(input) => setEmployeeId(input)}
                        onPressEnter={handleGetEmployee}
                        inputRefs={[inputRef]}
                    />
                </div>
            </div>
            {cardReadError ? (
                <ErrorModal
                    title={cardReadError.title}
                    errorMessage={cardReadError.message}
                    backgroundColor="grey"
                    onClose={() => clearCardNumber()}
                />
            ) : null}
        </>
    );
}
