import cn from "classnames";
import React, {useImperativeHandle, useRef} from "react";
import styles from "./TextInputWithResetButton.module.css";
import TextButton from "../TextButton";
import TextInput from "../TextInput";

const strings = {
    reset: "Reset",
};

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    className?: string;
    inputClassName?: string;
    placeholder: string;
    value: string;
    setValue: (value: string) => void;
    disabled?: boolean;
    resetLabel?: string;
};

export type TextInputWithResetButtonRefHandler = {
    inputRef: React.RefObject<HTMLInputElement>;
    resetButtonRef: React.RefObject<HTMLButtonElement>;
};

function TextInputWithResetButton(
    {
        className,
        inputClassName,
        value,
        setValue,
        placeholder,
        disabled,
        resetLabel,
        ...rest
    }: Props,
    ref: React.ForwardedRef<TextInputWithResetButtonRefHandler>,
) {
    const inputRef = useRef<HTMLInputElement>(null);
    const resetButtonRef = useRef<HTMLButtonElement>(null);

    useImperativeHandle(ref, () => ({
        inputRef: inputRef,
        resetButtonRef: resetButtonRef,
    }));

    return (
        <div className={cn(styles.inputContainer, className)}>
            <TextInput
                {...rest}
                className={cn(styles.input, inputClassName)}
                placeholder={placeholder}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                disabled={disabled}
                ref={inputRef}
                inputMode="none"
            />
            <TextButton
                label={resetLabel ?? strings.reset}
                onClick={() => setValue("")}
                disabled={!value || disabled}
                ref={resetButtonRef}
            />
        </div>
    );
}

export default React.forwardRef<TextInputWithResetButtonRefHandler, Props>(
    TextInputWithResetButton,
);
