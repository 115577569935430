import * as health from "../../lib/api/health";
import * as posActions from "../../lib/posActions";
import * as posModels from "../../lib/api/posModels";
import * as routeHelpers from "../../lib/routeHelpers";
import describeNetworkError from "../../lib/describeNetworkError";
import ErrorModal from "../ErrorModal";
import HealthCheckModal from "../HealthCheckModal";
import logger from "../../lib/logger";
import ManagerHeader from "../ManagerHeader";
import OutlineButton from "../OutlineButton";
import React, {useContext, useEffect, useState} from "react";
import styles from "./ManagerSettings.module.css";
import ToggleButton from "../ToggleButton";
import {API, Actions} from "habit-core";
import {CashierModeContext} from "../CashierModeContext";
import {useAppSelector, useAppDispatch} from "../../lib/hooks";
import {useNavigate} from "react-router-dom";
import {TillAssignmentContext} from "../TillAssignmentContext";

const strings = {
    title: "Settings",
    stationMode: "Station Mode",
    laneAssignment: "Lane Assignment",
    save: "Save",
    healthCheckSectionTitle: "Health Check",
    runHealthCheck: "Run Health Check",
    willRequireTill:
        "To change the station mode, you will be required to assign a till.",
};

const MODES: {label: posModels.StationMode; value: string}[] = [
    {label: "front_of_house_1", value: "Front of House 1"},
    {label: "front_of_house_2", value: "Front of House 2"},
    {label: "line_buster", value: "Line Buster"},
    {label: "drive_thru_order_taker", value: "Drive Thru Order Taker"},
    {
        label: "drive_thru_order_fulfillment",
        value: "Drive Thru Order Fulfillment",
    },
];

const LANE_OPTIONS: {value: posModels.LaneAssignment; label: string}[] = [
    {value: "lane_1", label: "Lane 1"},
    {value: "lane_2", label: "Lane 2"},
    {value: "both", label: "Both"},
];

export default function ManagerSettings() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {tillAssignmentSkipped, setTillAssignmentSkipped} = useContext(
        TillAssignmentContext,
    );
    const currentOrder = useAppSelector((state) => state.pos.currentOrder);
    const currentStationMode = useAppSelector(
        (state) => state.pos.station.mode,
    );
    const currentLaneAssignment = useAppSelector(
        (state) => state.pos.station.laneAssignment,
    );
    const {setCashierMode, isTraining} = useContext(CashierModeContext);
    const [selectedStationMode, setSelectedStationMode] =
        useState(currentStationMode);
    const [selectedLaneAssignment, setSelectedLaneAssignment] =
        useState<posModels.LaneAssignment>(currentLaneAssignment ?? "lane_1");
    const [saveDisabled, setSaveDisabled] = useState(true);

    const [healthCheckData, setHealthCheckData] = useState<
        posModels.ExhaustiveHealthCheckResponse | undefined
    >(undefined);
    const [healthCheckLoading, setHealthCheckLoading] = useState(false);
    const [healthCheckError, setHealthCheckError] = useState("");

    const runHealthCheck = () => {
        setHealthCheckLoading(true);
        health
            .getExhaustiveHealth()
            .then((data) => {
                setHealthCheckData(data);
            })
            .catch((err) => {
                logger.warn(err);
                setHealthCheckError(describeNetworkError(err).join("\n"));
            })
            .finally(() => {
                setHealthCheckLoading(false);
            });
    };
    const header = (
        <ManagerHeader
            label={strings.title}
            buttonLabel={strings.runHealthCheck}
            buttonOnClick={runHealthCheck}
            buttonLoading={healthCheckLoading}
        />
    );

    useEffect(() => {
        if (
            selectedStationMode !== currentStationMode ||
            selectedLaneAssignment !== currentLaneAssignment
        ) {
            setSaveDisabled(false);
        } else {
            setSaveDisabled(true);
        }
    }, [
        selectedStationMode,
        currentStationMode,
        selectedLaneAssignment,
        currentLaneAssignment,
    ]);

    function changeMode(
        newMode: posModels.StationMode,
        newlaneAssignment: posModels.LaneAssignment,
    ) {
        const finalLaneAssignment =
            newMode === "drive_thru_order_taker" || newMode === "line_buster"
                ? newlaneAssignment
                : null;
        dispatch(posActions.editStationMode(newMode, finalLaneAssignment));
        let newOrderType: API.models.OrderType = "drive_thru";
        if (newMode === "front_of_house_1" || newMode === "front_of_house_2") {
            newOrderType = "in_store";
        }
        dispatch(Actions.currentOrderActions.selectOrderType(newOrderType));

        if (
            currentStationMode === "drive_thru_order_taker" &&
            newMode !== "drive_thru_order_taker"
        ) {
            setTillAssignmentSkipped(false);
        }

        if (newMode !== "drive_thru_order_fulfillment" && !currentOrder.id) {
            dispatch(posActions.reserveCurrentOrderId(isTraining));
        }
        navigate(routeHelpers.deviceSetup(), {
            state: {
                skipCCRegistrationCheck: newMode === "drive_thru_order_taker",
            },
        });
        setCashierMode({type: "user"});
    }

    return (
        <>
            <div className={styles.container}>
                {header}
                <div className={styles.content}>
                    <div className={styles.section}>
                        <div className={styles.text}>{strings.stationMode}</div>
                        <div className={styles.modes}>
                            {MODES.map((m, i) => (
                                <ToggleButton
                                    key={i}
                                    className={styles.mode}
                                    label={m.value}
                                    checked={selectedStationMode === m.label}
                                    onChange={() =>
                                        setSelectedStationMode(m.label)
                                    }
                                />
                            ))}
                        </div>
                        {currentStationMode === "drive_thru_order_taker" &&
                        tillAssignmentSkipped ? (
                            <div className={styles.text}>
                                {strings.willRequireTill}
                            </div>
                        ) : null}
                    </div>
                    {selectedStationMode === "drive_thru_order_taker" ||
                    selectedStationMode === "line_buster" ? (
                        <div className={styles.section}>
                            <div className={styles.text}>
                                {strings.laneAssignment}
                            </div>
                            <div className={styles.laneAssignments}>
                                {LANE_OPTIONS.map((l) => (
                                    <ToggleButton
                                        key={l.value}
                                        className={styles.laneAssignment}
                                        label={l.label}
                                        checked={
                                            selectedLaneAssignment === l.value
                                        }
                                        onChange={() =>
                                            setSelectedLaneAssignment(l.value)
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className={styles.footer}>
                    <OutlineButton
                        className={styles.footerButton}
                        label={strings.save}
                        disabled={saveDisabled}
                        onClick={() =>
                            changeMode(
                                selectedStationMode,
                                selectedLaneAssignment,
                            )
                        }
                    />
                </div>
            </div>
            {healthCheckData ? (
                <HealthCheckModal
                    healthCheckData={healthCheckData}
                    onClose={() => setHealthCheckData(undefined)}
                />
            ) : null}
            {healthCheckError ? (
                <ErrorModal
                    title="Health Check Error"
                    errorMessage={healthCheckError}
                    showITInfo
                    onClose={() => setHealthCheckError("")}
                />
            ) : null}
        </>
    );
}
